import {
  deleteCookie,
  removeLocal,
  setLocal,
} from "../../../common/function/storage";
import {
  postLogin,
  replacePostLogin,
  getAuthVerify,
  getForgotPassword,
  getLogout,
  postSignUp,
  accountSetting,
  changePassword,
  contactUs,
  getSendMail,
  postResetPassword,
  getConnectWithBB,
  multipleInviteUser,
  getDashBoardCardData,
  getDashBoardTableData,
} from "../../../services/auth/index";

export const POST_AUTH_LOAD = "[LOGIN] POST_AUTH_LOAD";
export const POST_LOGIN = "[LOGIN] POST_LOGIN";
export const POST_AUTH_ERROR = "[LOGIN] POST_AUTH_ERROR";
export const POST_SIGNUP = "[SIGN_UP] POST_SIGNUP";
export const GET_AUTH_VERITY = "[AUTH] GET_AUTH_VERITY";
export const GET_LOGOUT = "[LOGOUT] GET_LOGOUT";
export const POST_ACCOUNT_SETTING = "[ACCOUNT_SETTING] POST_ACCOUNT_SETTING";
export const POST_CHANGE_PASSWORD = "[CHANGE_PASSWORD] POST_CHANGE_PASSWORD";
export const GET_SEND_MAIL = "[SEND] GET_SEND_MAIL";

/* Forgot password */
export const GET_FORGOT_PASSWORD = "[GOOGLE] GET_FORGOT_PASSWORD";
export const POST_RESET_PASSWORD = "[SEND] POST_RESET_PASSWORD";
// Contact us
export const POST_CONTACT_US = "[CONTACT] POST_CONTACT_US";
export function handleLogin(values, history) {
  return async (dispatch) => {
    try {
      dispatch({
        type: POST_AUTH_LOAD,
        payload: true,
      });
      const response = await postLogin(values);
      removeLocal("guest"),
        removeLocal("presence_event"),
        removeLocal("uuids"),
        setLocal("cast_channel", []);
      dispatch({
        type: POST_LOGIN,
        payload: response,
      });
      return response;
    } catch (error) {
      dispatch({
        type: POST_AUTH_ERROR,
        payload: { errorMessage: error.message },
      });
    }
  };
}

export function handleReplaceLogin(values, history) {
  return async (dispatch) => {
    try {
      dispatch({
        type: POST_AUTH_LOAD,
        payload: true,
      });
      const response = await replacePostLogin(values);
      removeLocal("guest"),
        removeLocal("presence_event"),
        removeLocal("uuids"),
        setLocal("cast_channel", []);
      dispatch({
        type: POST_LOGIN,
        payload: response,
      });
      return response;
    } catch (error) {
      dispatch({
        type: POST_AUTH_ERROR,
        payload: { errorMessage: error.message },
      });
    }
  };
}

export function handleAuthVerify(values, history, plan_id) {
  return async (dispatch) => {
    try {
      dispatch({
        type: POST_AUTH_LOAD,
        payload: true,
      });
      const response = await getAuthVerify(values);
      removeLocal("guest"),
        removeLocal("presence_event"),
        removeLocal("uuids"),
        setLocal("cast_channel", []);
        if(response){
          dispatch({
            type: GET_AUTH_VERITY,
            payload: response,
          });
        }
      return response;
    } catch (error) {
      dispatch({
        type: POST_AUTH_ERROR,
        payload: { errorMessage: error.message },
      });
      return error
    }
  };
}
/* Forgot password */
export function handleForgotPassword(values, history) {
  return async (dispatch) => {
    try {
      dispatch({
        type: POST_AUTH_LOAD,
        payload: true,
      });
      const response = await getForgotPassword(values);
      dispatch({
        type: GET_FORGOT_PASSWORD,
        payload: response,
      });
      history.push("/auth/signin");
    } catch (error) {
      dispatch({
        type: POST_AUTH_ERROR,
        payload: { errorMessage: error.message },
      });
    }
  };
}

export function handleLogout(history) {
  return async (dispatch) => {
    try {
      dispatch({
        type: POST_AUTH_LOAD,
        payload: true,
      });
      const response = await getLogout();
      dispatch({
        type: POST_LOGIN,
        payload: {},
      });
      dispatch({ type: POST_LOGIN, payload: null });

      dispatch({
        type: GET_LOGOUT,
        payload: response,
      });

      await Promise.all([
        removeLocal("guest"),
        removeLocal("presence_event"),
        removeLocal("uuids"),
        setLocal("cast_channel", []),
        removeLocal("access_token"),
        removeLocal("refresh_token"),
        removeLocal("persist:root"),
        // removeLocal("searchResult"),
        localStorage.clear(),
      ]);
      history.push("/");
    } catch (error) {
      dispatch({
        type: POST_AUTH_ERROR,
        payload: { errorMessage: error.message },
      });
    }
  };
}

export function handleResetPassword(values, history) {
  return async (dispatch) => {
    try {
      dispatch({
        type: POST_AUTH_LOAD,
        payload: true,
      });
      const response = await postResetPassword(values);
      dispatch({
        type: POST_RESET_PASSWORD,
        payload: response,
      });
      history.push("/auth/signin");
    } catch (error) {
      dispatch({
        type: POST_AUTH_ERROR,
        payload: { errorMessage: error.message },
      });
    }
  };
}

export function handleSignUp(values, history) {
  return async (dispatch) => {
    try {
      dispatch({
        type: POST_AUTH_LOAD,
        payload: true,
      });
      const response = await postSignUp(values);
      return response;
      // dispatch({
      //   type: POST_SIGNUP,
      //   payload: response,
      // });
    } catch (error) {
      dispatch({
        type: POST_AUTH_ERROR,
        payload: { errorMessage: error.message },
      });
    }
  };
}

/* Account Setting */
export function handleAccount(values) {
  return async (dispatch) => {
    try {
      dispatch({
        type: POST_AUTH_LOAD,
        payload: true,
      });
      const response = await accountSetting(values);

      dispatch({
        type: POST_ACCOUNT_SETTING,
        payload: response,
      });
      return response
    } catch (error) {
      dispatch({
        type: POST_AUTH_ERROR,
        payload: { errorMessage: error.message },
      });
    }
  };
}

/* Account Setting */
export function handleChangePass(values) {
  return async (dispatch) => {
    try {
      dispatch({
        type: POST_AUTH_LOAD,
        payload: true,
      });
      const response = await changePassword(values);
      dispatch({
        type: POST_CHANGE_PASSWORD,
        payload: response,
      });
      return response;
    } catch (error) {
      dispatch({
        type: POST_AUTH_ERROR,
        payload: { errorMessage: error.message },
      });
    }
  };
}

/* Contact us */
export function handleContactUs(values, history) {
  return async (dispatch) => {
    try {
      dispatch({
        type: POST_AUTH_LOAD,
        payload: true,
      });
      const response = await contactUs(values);
      dispatch({
        type: POST_CONTACT_US,
        payload: response,
      });
      return response
    } catch (error) {
      dispatch({
        type: POST_AUTH_ERROR,
        payload: { errorMessage: error.message },
      });
    }
  };
}

export function handleSendMail(values, history) {
  return async (dispatch) => {
    try {
      dispatch({
        type: POST_AUTH_LOAD,
        payload: true,
      });
      const response = await getSendMail(values);
      dispatch({
        type: GET_SEND_MAIL,
        payload: response,
      });
      history.push("/auth/signin");
    } catch (error) {
      dispatch({
        type: POST_AUTH_ERROR,
        payload: { errorMessage: error.message },
      });
    }
  };
}

export function handleConnectWithBB() {
  return async (dispatch) => {
    try {
      dispatch({
        type: POST_AUTH_LOAD,
        payload: true,
      });
      const response = await getConnectWithBB();
      return response;
    } catch (error) {
      dispatch({
        type: POST_AUTH_ERROR,
        payload: { errorMessage: error.message },
      });
    }
  };
}

export function handleInviteUser(values, history) {
  return async (dispatch) => {
    try {
      dispatch({
        type: POST_AUTH_LOAD,
        payload: true,
      });
      let emails = { emails: values };
      const response = await multipleInviteUser(emails);
      return response;
    } catch (error) {
      dispatch({
        type: POST_AUTH_ERROR,
        payload: { errorMessage: error.message },
      });
    }
  };
}

export function handleGetDashBoardCardData(params) {
  return async (dispatch) => {
    try {
      dispatch({
        type: POST_AUTH_LOAD,
        payload: true,
      });
      const response = await getDashBoardCardData(params);
      return response;
    } catch (error) {
      dispatch({
        type: POST_AUTH_ERROR,
        payload: { errorMessage: error.message },
      });
    }
  };
}

export function handleGetDashBoardData(page, size, search) {
  return async (dispatch) => {
    try {
      dispatch({
        type: POST_ALBUMS_LOAD,
        payload: true,
      });
      const response = await getDashBoardTableData(page, size, search);
      return response;
    } catch (error) {
      dispatch({
        type: POST_ALBUMS_ERROR,
        payload: { errorMessage: error?.message },
      });
    }
  };
}
