import React, { useEffect } from "react";
import Head from "next/head";
import Script from "next/script";
import { formatTime } from "../common/function/function";

const head_seo_final = (props) => {
  return (
    <div>
      <Head>
        {/* <meta charset="utf-8" /> */}
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin="true" />
        <link
          rel="stylesheet"
          as="font"
          href="https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,400;0,500;0,600;0,700;0,800;1,400;1,500;1,600&display=swap"
          async="true"
          // crossorigin
        />

        {/* <link rel="preconnect" href="https://pro.fontawesome.com" async="true" />
      <link
        rel="stylesheet"
        href="https://pro.fontawesome.com/releases/v5.12.1/css/all.css"
        async="true"
      /> */}

        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/apple-touch-icon.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/favicon-16x16.png"
        />
        {/* <link rel="manifest" href="/site.webmanifest" /> */}
        <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#5bbad5" />
        <meta name="msapplication-TileColor" content="#da532c" />
        <meta name="theme-color" content="#ffffff" />
        {process.env.NODE_ENV === "production" &&
        typeof window !== "undefined" &&
        window.location?.origin === "https://battlebards.com" ? (
          <React.Fragment>
            {/*     <script
            async
            dangerouslySetInnerHTML={{
              __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','GTM-WTDKL5Q');`,
            }}
          ></script> */}

            <script
              defer
              dangerouslySetInnerHTML={{
                __html: `(window.onUsersnapLoad = function(api) {
              api.init();
            }, var script = document.createElement('script');
            script.defer = 1;
            script.src = 'https://widget.usersnap.com/global/load/3bb07617-5fa7-4b18-a7a8-86b6496b0002?onload=onUsersnapLoad';
            document.getElementsByTagName('head')[0].appendChild(script))`,
            }}
          ></script>

            <script
              defer
              dangerouslySetInnerHTML={{
                __html: `(window.zESettings = {
            webWidget: {
              chat: {
                connectOnPageLoad: false,
              },
            },
          })`,
              }}
            ></script>
            <script
              defer
              dangerouslySetInnerHTML={{
                __html: `(function(c,l,a,r,i,t,y){
                c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
                t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
                y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
            })(window, document, "clarity", "script", "fv3vninolo");`,
            }}
          ></script>
        </React.Fragment>
      ) : null}
      {process.env.NODE_ENV === "production" &&
      typeof window !== "undefined" &&
      window.location?.origin === "https://battlebards.com" ? (
        <React.Fragment>
          {/* <link rel="dns-prefetch" href="https://www.googletagmanager.com"></link>
          <link rel="preconnect" href="https://www.googletagmanager.com"></link>
          <Script
            async
            id=""
            src="https://www.googletagmanager.com/ns.html?id=GTM-WTDKL5Q"
            strategy="lazyOnload"
          ></Script> */}
          <link rel="dns-prefetch" href="https://zdassets.com"></link>
          <link rel="preconnect" href="https://static.zdassets.com"></link>
          <Script
            defer
            id="ze-snippet"
            strategy="lazyOnload"
            src="https://static.zdassets.com/ekr/snippet.js?key=47c5d1d9-2627-470b-891c-e885c0dbbe77"
          ></Script>
        </React.Fragment>
      ) : null}
      </Head>
    </div>
  );
};
export default head_seo_final;
