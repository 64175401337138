import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector, useStore } from "react-redux";
import { Range, getTrackBackground, Direction } from "react-range";
import { useRouter } from "next/dist/client/router";
import style from "./wavesurfer.module.scss";
import {
  handleGeneratePeeks,
  handlePlayTimeUrl,
  handlePostSQS,
} from "../../redux-store/actions/albums";
import { formatTime, unixTimeStamp } from "../../common/function/function";
import musicImg from "../../images/w-250-h-325.jpg";
import useWindowDimensions from "../../common/function/hooks/use-window-dimensions";
import ImageHOC from "../image-hoc";
import { getLocal } from "../../common/function/storage";
import { getCurWaveTime } from "../../common/function/waveServices";
export default function Waveform({ flag }) {
  const waveformRef = useRef(null);
  const waveformRefPeaks = useRef(null);
  const _TOKEN = getLocal("access_token");
  const wavesurfer = useRef(null);
  const wavesurferPeaks = useRef(null);
  const _MY_LIBRARY_TABS = useSelector((e) => e?.common?.myLibraryTabs);
  const location = useRouter();
  const dispatch = useDispatch();
  const store = useStore();
  const { width } = useWindowDimensions();
  let isMobile = width <= 767;
  const [volume, setVolume] = useState([0.5]);
  const [volumeToggle, setVolumeToggle] = useState(false);

  const [muteToggle, setMuteToggle] = useState(false);

  let generatePeaksData = false;
  let formWaveSurferOptions = {};

  const _CURRENT_PLAY_DATA = useSelector(
    (state) => state.common?.currentPlayData
  );
  const _PLAYING_STATE = useSelector((state) => state.common.playingState);
  const _AUDIO_QUALITY_MODAL = useSelector(
    (state) => state.common.audioQualityModal
  );

  const [audioContext, setAudioContext] = useState(null);

  useEffect(() => {
    let AudioContext =
      window.AudioContext || // Default
      window.webkitAudioContext;
    // Safari and old versions of Chrome
    AudioContext = new AudioContext({
      sampleRate: 44100,
    });
    setAudioContext(AudioContext);
  }, []);

  useEffect(() => {
    if (_CURRENT_PLAY_DATA.tracksData.length === 0) {
      handle.destroyPlayer();
    }
  }, [_CURRENT_PLAY_DATA]);

  const formWaveSurferOptionsPeaks = (ref) => ({
    container: ref,
    progressColor: "purple",
    cursorColor: "OrangeRed",
    barWidth: 2,
    barRadius: 1,
    height: 50,
    normalize: true,
    partialRender: true,
    responsive: true,
    clientWidth: 0,
  });

  const peaksGenerate = async (presignedurl) => {
    const WaveSurferPeaks = (await import("wavesurfer.js")).default;
    const optionsPeaks = formWaveSurferOptionsPeaks(waveformRefPeaks?.current);
    wavesurferPeaks.current = WaveSurferPeaks?.create(optionsPeaks);
    wavesurferPeaks?.current?.on("ready", function () {
      if (generatePeaksData) {
        const peaks = wavesurferPeaks?.current?.backend?.getPeaks(300, 0, 300);
        const duration = wavesurferPeaks?.current?.backend?.getDuration();
        let params = {
          track:
            _CURRENT_PLAY_DATA?.tracksData?.length &&
            _CURRENT_PLAY_DATA?.tracksData[
              _CURRENT_PLAY_DATA?.currentPlayingIndex
            ]?.track_id,
          peaks: peaks.join(","),
          duration: duration?.toString(),
        };
        dispatch(handleGeneratePeeks(params));
      }
    });
    wavesurferPeaks?.current?.load(presignedurl);
  };

  useEffect(async () => {
    formWaveSurferOptions = (ref) => ({
      container: ref,
      waveColor: "#777",
      progressColor: "#dcba6c",
      cursorColor: "OrangeRed",
      barWidth: 2,
      barRadius: 1,
      responsive: false,
      height: 50,
      normalize: true,
      // partialRender: true,
      clientWidth: 0,
      backend: "MediaElement",
      interact: false,
      audioScriptProcessor: audioContext && new AnalyserNode(audioContext),
      audioContext,
      xhr: {
        requestHeaders: [{ responseType: "arraybuffer" }],
      },
    });
    const WaveSurfer = (await import("wavesurfer.js")).default;
    const options = formWaveSurferOptions(waveformRef.current);

    const UpdateLoadingFlag = (Percentage) => {
      if (document.getElementById("loading_flag")) {
        document.getElementById("loading_flag").innerText =
          "Loading " + Percentage + "%";
        if (Percentage >= 100) {
          document.getElementById("loading_flag").style.display = "none";
        } else {
          document.getElementById("loading_flag").style.display = "block";
        }
      }
    };

    wavesurfer.current = WaveSurfer?.create(options);

    setMuteToggle(volume[0] !== 0 ? false : true);
    wavesurfer?.current?.setVolume(volume[0]);

    wavesurfer?.current?.on("ready", async function () {
      if (!wavesurfer.current.isPlaying() && wavesurfer.current.isReady) {
        audioContext?.resume();
        if (wavesurfer.current) {
          wavesurfer.current?.drawBuffer();
          wavesurfer.current.params.interact = true;
          setTimeout(() => {
            wavesurfer?.current?.play();
          }, 200);
        }
      } else {
        wavesurfer?.current?.pause();
      }
    });

    wavesurfer?.current?.on("finish", function () {
      handle.destroyPlayer();
      const _CURRENT_PLAY_DATA_STORE = store.getState().common?.currentPlayData;

      if (_CURRENT_PLAY_DATA_STORE?.isMultiplePlaying) {
        if (
          _CURRENT_PLAY_DATA_STORE?.tracksData?.length >
          _CURRENT_PLAY_DATA_STORE?.currentPlayingIndex
        ) {
          handle.destroyPlayer();
          handle.handleNextTracks();
        }
      } else {
        handle.destroyPlayer();
        dispatch({
          type: "CURRENT_PLAY_DATA",
          payload: {
            isMultiplePlaying: false,
            currentPlayingIndex: 0,
            library: false,
            tracksData: [],
          },
        });
      }
    });
  }, [_CURRENT_PLAY_DATA]);

  let payload = {};
  useEffect(() => {
    if (_CURRENT_PLAY_DATA?.tracksData?.length) {
      handle.commonWave(
        _CURRENT_PLAY_DATA?.tracksData?.length &&
          _CURRENT_PLAY_DATA?.tracksData[
            _CURRENT_PLAY_DATA?.currentPlayingIndex
          ]?.track_id
      );
    }
  }, [_CURRENT_PLAY_DATA]);

  useEffect(() => {
    dispatch({ type: "WAVE_SURFER", payload: wavesurfer.current });
  }, [wavesurfer.current]);

  const handle = {
    destroyPlayer: () => {
      wavesurfer.current?.destroy();
      wavesurfer.current = null;
    },
    isPurchase: () => {
      if (
        _CURRENT_PLAY_DATA &&
        _CURRENT_PLAY_DATA.tracksData &&
        _CURRENT_PLAY_DATA?.tracksData[_CURRENT_PLAY_DATA?.currentPlayingIndex]
          ?.is_purchased
      ) {
        return (
          _CURRENT_PLAY_DATA &&
          _CURRENT_PLAY_DATA.tracksData &&
          _CURRENT_PLAY_DATA?.tracksData[
            _CURRENT_PLAY_DATA?.currentPlayingIndex
          ]?.is_purchased
        );
      } else if (_MY_LIBRARY_TABS === "Purchased") {
        return true;
      } else {
        return false;
      }
    },
    commonWave: async (i) => {
      let tempTrack;
      handle.destroyPlayer();
      if (i !== undefined) tempTrack = await dispatch(handlePlayTimeUrl(i));
      dispatch({ type: "WAVE_SURFER", payload: wavesurfer.current });
      dispatch({ type: "PLAYING_STATE", payload: false });

      payload = {
        events: _PLAYING_STATE ? "play" : "pause",
        source: _CURRENT_PLAY_DATA?.library === false ? "store" : "my library",
        track_id:
          _CURRENT_PLAY_DATA?.tracksData?.length &&
          _CURRENT_PLAY_DATA?.tracksData[
            _CURRENT_PLAY_DATA?.currentPlayingIndex
          ]?.track_id,
        start: getCurWaveTime(wavesurfer?.current),
        end: getCurWaveTime(wavesurfer?.current),
        is_purchased: handle.isPurchase(),
        is_subscription: _TOKEN ? true : false,
        event_timestamp: unixTimeStamp(),
      };

      dispatch(handlePostSQS(payload));

      if (tempTrack)
        if (
          typeof tempTrack?.peaks === "string" &&
          tempTrack?.peaks?.split(",").length > 1
        ) {
          wavesurfer?.current?.load(
            tempTrack?.presignedurl,
            tempTrack?.peaks?.split(",")
          );
        } else {
          wavesurfer?.current?.load(tempTrack?.presignedurl);
        }
        if (tempTrack?.peaks === "" || tempTrack?.peaks === null) {
        generatePeaksData = true;
        peaksGenerate(tempTrack?.presignedurl);
      }
    },
    handlePlayPause: () => {
      payload = {
        events: _PLAYING_STATE ? "play" : "pause",
        source: _CURRENT_PLAY_DATA?.library === false ? "store" : "my library",
        track_id:
          _CURRENT_PLAY_DATA?.tracksData?.length &&
          _CURRENT_PLAY_DATA?.tracksData[
            _CURRENT_PLAY_DATA?.currentPlayingIndex
          ]?.track_id,
        start: getCurWaveTime(wavesurfer?.current),
        end: getCurWaveTime(wavesurfer?.current),
        is_purchased: handle.isPurchase(),
        is_subscription: _TOKEN ? true : false,
        event_timestamp: unixTimeStamp(),
      };
      dispatch({ type: "PLAYING_STATE", payload: !_PLAYING_STATE });
      if (wavesurfer?.current?.isReady) {
        wavesurfer.current.playPause();
        dispatch(handlePostSQS(payload));
      }
    },
    handlePreviousTracks: () => {
      dispatch({
        type: "CURRENT_PLAY_DATA",
        payload: {
          isMultiplePlaying: true,
          currentPlayingIndex: _CURRENT_PLAY_DATA?.currentPlayingIndex - 1,
          library: false,
          tracksData: _CURRENT_PLAY_DATA?.tracksData,
        },
      });
    },
    handleNextTracks: () => {
      dispatch({
        type: "CURRENT_PLAY_DATA",
        payload: {
          isMultiplePlaying: true,
          currentPlayingIndex: _CURRENT_PLAY_DATA?.currentPlayingIndex + 1,
          library: false,
          tracksData: _CURRENT_PLAY_DATA?.tracksData,
        },
      });
    },
    stopAll: () => {
      wavesurfer?.current?.pause();
      dispatch({
        type: "CURRENT_PLAY_DATA",
        payload: {
          isMultiplePlaying: false,
          currentPlayingIndex: 0,
          library: false,
          tracksData: [],
        },
      });
      payload = {
        source: "store",
        track_id: "",
        start: 0,
        end: 0,
        is_purchased: false,
        is_subscription: _TOKEN ? true : false,
        event_timestamp: 0,
      };
    },
  };

  const onVolumeChange = (e) => {
    const newVolume = e;
    if (newVolume) {
      setMuteToggle(newVolume[0] !== 0 ? false : true);
      wavesurfer.current.setVolume(newVolume[0]);
    }
  };
  const substring = ".jpg";
  const multiRoute = () =>
    location?.query?.albumId !== undefined ||
    location?.pathname === "/store/albums" ||
    location?.pathname === "/store/explore" ||
    location?.pathname === "/checkout" ||
    location?.pathname === "/plan" ||
    location?.pathname === "/account" ||
    location?.pathname === "/store/library" ||
    location?.query?.trackId !== undefined;
  return (
    <div
      style={{
        display:
          _CURRENT_PLAY_DATA &&
          _CURRENT_PLAY_DATA.tracksData &&
          _CURRENT_PLAY_DATA?.tracksData[
            _CURRENT_PLAY_DATA?.currentPlayingIndex
          ]?.track_id
            ? "block"
            : "none",
      }}
      className={`${
        multiRoute() && !_AUDIO_QUALITY_MODAL
          ? style.waveform_bottom
          : _AUDIO_QUALITY_MODAL
          ? style.waveform_modal
          : style.waveform_bottom_homePage
      }`}
    >
      <div
        className={`wavesurfer-main-div ${
          _AUDIO_QUALITY_MODAL ? "audio-quality-modal-div" : ""
        }`}
      >
        <div className="wavesurfer-control-div">
          <div className="wavesurfer-play-push-icon-div ">
            {!flag ? (
              <div className="d-flex">
                {/*  {_CURRENT_PLAY_DATA?.tracksData.length > 1 &&
                _CURRENT_PLAY_DATA?.currentPlayingIndex !== 0 && ( */}
                <i
                  className="icon-backward cursor-pointer"
                  style={{
                    opacity: !_CURRENT_PLAY_DATA?.isMultiplePlaying
                      ? "0.4"
                      : _CURRENT_PLAY_DATA?.tracksData.length === 1 ||
                        (_CURRENT_PLAY_DATA?.tracksData.length > 1 &&
                          _CURRENT_PLAY_DATA?.currentPlayingIndex === 0)
                      ? "0.4"
                      : null,
                  }}
                  onClick={() => {
                    _CURRENT_PLAY_DATA?.currentPlayingIndex !== 0 &&
                      _CURRENT_PLAY_DATA?.isMultiplePlaying &&
                      handle.handlePreviousTracks();
                  }}
                ></i>
                {/* )} */}
              </div>
            ) : null}

            {!_AUDIO_QUALITY_MODAL ? (
              <button onClick={handle.handlePlayPause}>
                {_PLAYING_STATE ? (
                  <i className="icon-play-icon cursor-pointer"></i>
                ) : (
                  <i className="icon-pause2 cursor-pointer"></i>
                )}
              </button>
            ) : null}
            {!flag ? (
              <div className="d-flex">
                {/* {_CURRENT_PLAY_DATA?.tracksData.length > 1 &&
                _CURRENT_PLAY_DATA?.currentPlayingIndex + 1 !==
                  _CURRENT_PLAY_DATA?.tracksData?.length && ( */}
                <i
                  className="icon-forward cursor-pointer"
                  style={{
                    opacity:
                      !_CURRENT_PLAY_DATA?.isMultiplePlaying ||
                      (_CURRENT_PLAY_DATA &&
                        _CURRENT_PLAY_DATA.tracksData &&
                        _CURRENT_PLAY_DATA?.tracksData[
                          _CURRENT_PLAY_DATA?.currentPlayingIndex
                        ]?.track_id ===
                          _CURRENT_PLAY_DATA?.tracksData[
                            _CURRENT_PLAY_DATA?.tracksData?.length - 1
                          ]?.track_id)
                        ? "0.4"
                        : null,
                  }}
                  onClick={() => {
                    _CURRENT_PLAY_DATA &&
                      _CURRENT_PLAY_DATA.tracksData &&
                      _CURRENT_PLAY_DATA?.tracksData[
                        _CURRENT_PLAY_DATA?.currentPlayingIndex
                      ]?.track_id !==
                        _CURRENT_PLAY_DATA?.tracksData[
                          _CURRENT_PLAY_DATA?.tracksData?.length - 1
                        ]?.track_id &&
                      _CURRENT_PLAY_DATA?.isMultiplePlaying &&
                      handle.handleNextTracks();
                  }}
                ></i>
                {/* )} */}
              </div>
            ) : null}
          </div>
        </div>

        <div className="wavesurfer-title-wave-div">
          <div id="loading_flag"></div>
          <div id="waveform" ref={waveformRef} className="waveform_website" />
        </div>

        <div
          style={{ display: "none" }}
          id={`waveforms`}
          ref={waveformRefPeaks}
        ></div>

        <div className="wavesurfer-sound-div">
          <div className="wavesurfer-time-main-div">
            <p className={style.music_wave_time}>
              {formatTime(
                parseInt(
                  _CURRENT_PLAY_DATA &&
                    _CURRENT_PLAY_DATA.tracksData &&
                    _CURRENT_PLAY_DATA?.tracksData[
                      _CURRENT_PLAY_DATA?.currentPlayingIndex
                    ]?.duration
                )
              )}
            </p>
          </div>
          <div className="wavesurfer-sound-main-div">
            <i
              className={
                muteToggle
                  ? "icon-volume-mute-svgrepo-com"
                  : "icon-volume-svgrepo-com cursor-pointer"
              }
              onClick={(e) => {
                setVolumeToggle(!volumeToggle);
              }}
            ></i>
            {volumeToggle && !isMobile ? (
              <Range
                values={muteToggle ? [0] : volume}
                step={0.1}
                min={0}
                max={1}
                direction={Direction.Up}
                onChange={(volume) => {
                  setVolume(volume);
                  onVolumeChange(volume);
                }}
                onFinalChange={() => onVolumeChange(volume)}
                renderTrack={({ props, children }) => (
                  <div
                    className="wavesurfer-soundbar-div"
                    onMouseDown={props.onMouseDown}
                    onTouchStart={props.onTouchStart}
                    style={{
                      ...props.style,
                      display: "flex",
                      width: "4px",
                      marginLeft: "10px",
                    }}
                  >
                    <div
                      ref={props.ref}
                      style={{
                        height: "50px",
                        width: "4px",
                        borderRadius: "4px",
                        background: getTrackBackground({
                          values: muteToggle ? [0] : volume,
                          colors: ["#9e854d", "#a7a7a7"],
                          min: 0,
                          max: 1,
                          direction: Direction.Up,
                        }),
                        alignSelf: "center",
                      }}
                    >
                      {children}
                    </div>
                  </div>
                )}
                renderThumb={({ props, isDragged }) => (
                  <div
                    {...props}
                    style={{
                      ...props.style,
                      height: "16px",
                      width: "16px",
                      borderRadius: "10px",
                      backgroundColor: "#9e854d",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      outline: "none",
                    }}
                  ></div>
                )}
              />
            ) : null}
            {volumeToggle && isMobile ? (
              <Range
                values={muteToggle ? [0] : volume}
                step={0.1}
                min={0}
                max={1}
                onChange={(values) => setVolume(values)}
                onFinalChange={() => onVolumeChange(volume)}
                renderTrack={({ props, children }) => (
                  <div
                    className="wavesurfer-soundbar-div"
                    onMouseDown={props.onMouseDown}
                    onTouchStart={props.onTouchStart}
                    style={{
                      ...props.style,
                      display: "flex",
                      width: "100%",
                      marginLeft: "10px",
                    }}
                  >
                    <div
                      ref={props.ref}
                      style={{
                        height: "7px",
                        width: "100%",
                        borderRadius: "4px",
                        background: getTrackBackground({
                          values: muteToggle ? [0] : volume,
                          colors: ["#9e854d", "#a7a7a7"],
                          min: 0,
                          max: 1,
                        }),
                        alignSelf: "center",
                      }}
                    >
                      {children}
                    </div>
                  </div>
                )}
                renderThumb={({ props, isDragged }) => (
                  <div
                    {...props}
                    style={{
                      ...props.style,
                      height: "16px",
                      width: "16px",
                      borderRadius: "10px",
                      backgroundColor: "#9e854d",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      outline: "none",
                    }}
                  ></div>
                )}
              />
            ) : null}
          </div>
        </div>

        <div className="wavesurfer-music-title-img-div">
          <div className="wavesurfer-img-div">
            <ImageHOC
              src={
                _CURRENT_PLAY_DATA &&
                _CURRENT_PLAY_DATA.tracksData &&
                _CURRENT_PLAY_DATA?.tracksData[
                  _CURRENT_PLAY_DATA?.currentPlayingIndex
                ]?.cover_image_large?.includes(substring)
                  ? _CURRENT_PLAY_DATA &&
                    _CURRENT_PLAY_DATA.tracksData &&
                    _CURRENT_PLAY_DATA?.tracksData[
                      _CURRENT_PLAY_DATA?.currentPlayingIndex
                    ]?.cover_image_large
                  : musicImg
              }
              alt={
                _CURRENT_PLAY_DATA &&
                _CURRENT_PLAY_DATA.tracksData &&
                _CURRENT_PLAY_DATA?.tracksData[
                  _CURRENT_PLAY_DATA?.currentPlayingIndex
                ]?.title
              }
              loading={"eager"}
              priority
              width={50}
              height={50}
              sampleImage={musicImg}
              redirect={() => null}
            />
          </div>
          <div className="wavesurfer-music-title-div">
            <p className={style.music_wave_title}>
              {_CURRENT_PLAY_DATA &&
                _CURRENT_PLAY_DATA.tracksData &&
                _CURRENT_PLAY_DATA?.tracksData[
                  _CURRENT_PLAY_DATA?.currentPlayingIndex
                ]?.title}
            </p>
          </div>
        </div>

        <div className="wavesurfer-close-icon-div">
          <p
            className={`cursor-pointer ${style.close_wave}`}
            onClick={() => handle.stopAll()}
          >
            <i
              className="icon-close-solid fa-lg active fs-20"
              aria-hidden="true"
            />
          </p>
        </div>
      </div>
    </div>
  );
}
