// import { Decryption, Encryption } from "./function";
import Cookies from "universal-cookie";
const cookies = new Cookies();

let domain;

if (typeof window !== "undefined") {
  let do_firstIndex = new URL(window.location.href);
  do_firstIndex = do_firstIndex.hostname.split(".");
  let do_lastIndex = do_firstIndex[do_firstIndex.length - 1];
  do_firstIndex = do_firstIndex[do_firstIndex.length - 2];
  let finalConcat = "." + do_firstIndex + "." + do_lastIndex;
  domain = process.env.NODE_ENV === "production" ? finalConcat : "localhost";
}

export const setLocal = (key, value) => {
  if (value != undefined || value != null) checkCookie(key, value, 365);
  return localStorage.setItem(key, value);
};

export const getLocal = (key) => {
  if (typeof window != "undefined") {
    // Perform localStorage action
    // const item = localStorage.getItem(key);
    const item1 = getCookie(key);
    return item1;
  }
};
export const removeLocal = (key) => {
  if (typeof window != "undefined") {
    // Perform localStorage action
    const item = localStorage.removeItem(key);
    deleteCookie(key);
    return item;
  }
};

// Function to fetch the cookie periodically
export const startFetchingCookiePeriodically = (cname, interval) => {
  const fetchInterval = setInterval(() => {
    getCookie(cname); // Fetch the cookie
  }, interval);
};

export const setCookie = (cname, cvalue) => {
  var oneYearFromNow = new Date();
  if (cname === "access_token") {
    // current changes ↓
    // var minutes = process.env.NEXT_PUBLIC_COOKIE_ACCESS_TOKEN
    //   ? process.env.NEXT_PUBLIC_COOKIE_ACCESS_TOKEN
    //   : 1407;

    // static changes ↓
    var minutes = 1440;
    oneYearFromNow.setTime(oneYearFromNow.getTime() + minutes * 60 * 1000);
    /* oneYearFromNow.setDate(oneYearFromNow.getDate() + 1); */
  } else {
    oneYearFromNow.setFullYear(oneYearFromNow.getFullYear() + 1);
  }
  const encryptedValue = encrypt(cvalue);
  cookies.set(cname, encryptedValue, {
    path: "/",
    expires: oneYearFromNow,
    domain,
  });
};

let fetchInterval; // Global variable to store the interval ID
const fetchIntervalTime = 10 * 60 * 1000; // 10 minutes in milliseconds

export const getCookie = (cname) => {
  if (!fetchInterval) {
    // Start fetching the cookie periodically
    fetchInterval = setInterval(() => {
      getCookie(cname); // Fetch the cookie without periodic fetching
    }, fetchIntervalTime);
  }

  // Retrieve the cookie value corresponding to cname
  try {
    const getLocalCookie = cookies.get(cname);

    // Check if the cookie value is not falsy (e.g., not null, undefined, empty string, or 0)
    if (getLocalCookie) {
      try {
        // Decrypt the cookie value
        const decryptedValue = decrypt(getLocalCookie);

        try {
          // Attempt to parse the decrypted value as JSON
          const parsedValue = JSON.parse(decryptedValue);

          // Check if the parsed value is an object
          if (typeof parsedValue === "object" && parsedValue !== null) {
            // If it's an object (likely a JSON string before decryption), return the parsed object
            return parsedValue;
          }
        } catch (jsonParseError) {
          // Handle JSON parsing errors
          //console.error("Error parsing JSON:", jsonParseError);
        }
        // If parsing fails or the parsed value is not an object, return the decrypted value as is
        return decryptedValue;
      } catch (decryptionError) {
        // Handle decryption errors
        // console.error("Error decrypting:", decryptionError);
        const cookiesList = cookies.getAll();
        Object.keys(cookiesList).forEach((cookieName) => {
          cookies.remove(cookieName);
        });
        setLocal("routers", "/");
        return null;
      }
    }

    // If the cookie value is falsy (e.g., not found or empty), return null
    return null;
  } catch (error) {
    console.error("Error retrieving cookie: ", error);
    return null;
  }
};

const checkCookie = (cname, cvalue, type) => {
  let isCookieAvailable = getCookie(cname);
  if (isCookieAvailable !== undefined && type !== "searchOpenResult") {
    deleteCookie(cname);
  }
  if (
    isCookieAvailable == "" ||
    isCookieAvailable == null ||
    isCookieAvailable == undefined
  ) {
    setCookie(cname, cvalue, type);
  }
};

export const deleteCookie = (cname) => {
  cookies.remove(cname, { path: "/", domain });
};

// Custom encryption function using Caesar cipher with a fixed shift of 3
export const encrypt = (text) => {
  let result = "";
  for (let i = 0; i < text?.length; i++) {
    let char = text[i];
    if (char.match(/[a-z]/i)) {
      const code = text?.charCodeAt(i);
      if (code >= 65 && code <= 90) {
        char = String?.fromCharCode(((code - 65 + 3) % 26) + 65); // Uppercase letters
      } else if (code >= 97 && code <= 122) {
        char = String?.fromCharCode(((code - 97 + 3) % 26) + 97); // Lowercase letters
      }
    }
    result += char;
  }
  return result;
};

// Custom decryption function using Caesar cipher with a fixed shift of -3
export const decrypt = (text) => {
  let result = "";
  for (let i = 0; i < text?.length; i++) {
    let char = text[i];
    if (char.match(/[a-z]/i)) {
      const code = text?.charCodeAt(i);
      if (code >= 65 && code <= 90) {
        char = String?.fromCharCode(((code - 65 - 3 + 26) % 26) + 65); // Uppercase letters
      } else if (code >= 97 && code <= 122) {
        char = String?.fromCharCode(((code - 97 - 3 + 26) % 26) + 97); // Lowercase letters
      }
    }
    result += char;
  }
  return result;
};
