import axios from "axios";
import { refreshAccessToken } from "../auth";
import { axiosInstance } from "../axios-config";
import { redirectFn, showToast } from "../../common/function/function";
import { getLocal, removeLocal, setLocal } from "../../common/function/storage";
import { errorLog } from "../../common/utils";
import { homePageCount } from "../../redux-store/actions/albums";

let isAlreadyFetchingAccessToken = false;

let subscribers = [];

export const downloadAudioFromBlob = (blob, name, type) => {
  const url = window.URL.createObjectURL(new Blob([blob], { type: type }));
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute(
    "download",
    `${name}${type === "audio/mpeg" ? ".mp3" : ".zip"}`
  );

  document.body.appendChild(link);

  link.click();

  link.parentNode.removeChild(link);
};

export const getAlbums = () =>
  new Promise((resolve, reject) =>
    axiosInstance
      .get(`albums`)
      .then((response) => {
        resolve(response.data.data);
      })
      .catch((error) => {
        errorLog(error);
        catchErrorShowError(error, reject);
      })
  );

/* get Albums Filter*/
export const getFilerAlbums = (value) =>
  new Promise((resolve, reject) =>
    axiosInstance
      .get(
        `albums?${`${value.year ? `year=${value.year}` : ""}${
          value.audio_type ? `&audio_type=${value.audio_type}` : ""
        }${value.search ? `&search=${value.search}` : ""} `}`
      )
      .then((response) => {
        resolve(response?.data?.data);
      })
      .catch((error) => {
        errorLog(error);
        catchErrorShowError(error, reject);
        reject(error);
      })
  );

/* get All Albums */
export const getAudioTypes = () =>
  new Promise((resolve, reject) =>
    axiosInstance
      .get(`audio-types`)
      .then((response) => {
        resolve(response && response?.data);
      })
      .catch((error) => {
        errorLog(error);
        catchErrorShowError(error, reject);
      })
  );

/*PreSigned Url */
export const getPlayTimeUrl = (value, flagEmbedded, is_home) =>
  new Promise((resolve, reject) =>
    axiosInstance
      .get(
        `tracks/${value}?${`${
          is_home
            ? `isHome=${is_home}`
            : flagEmbedded
            ? `isHome=${flagEmbedded}`
            : ""
        }`}`
      )
      .then((response) => {
        resolve(response && response?.data && response?.data?.data);
      })
      .catch((error) => {
        catchErrorWrong(error, reject);
      })
  );

/*Generate Peaks */
export const generatePeekAPI = (value) =>
  new Promise((resolve, reject) =>
    axiosInstance
      .post(`tracks/peaks-duration-update`, value)
      .then((response) => {
        resolve(response.data.data);
      })
      .catch((error) => {
        errorLog(error);
        reject({ message: `Something Went Wrong` });
      })
  );
/*Album all download */
export const getAudioAllDownload = async (value, name) => {
  const token = getLocal("access_token");
  try {
    const response = await fetch(
      `${process.env.NEXT_PUBLIC_BACKEND_API_URL}tracks/download/zip`,
      {
        method: "POST",
        headers: {
          Accept: "arrayBuffer",
          "Content-Type": "application/json",
          Authorization: token,
        },
        body: JSON.stringify({ tracks: value }),
      }
    );
    return response;
  } catch (error) {
    console.error(error);
  }
};

/*Get Trending Track */
export const getTrendingTrack = () =>
  new Promise((resolve, reject) =>
    axiosInstance
      .get(`tracks/explore/trending`)
      .then((response) => {
        resolve(response.data.data);
      })
      .catch((error) => {
        catchErrorWrong(error, reject);
      })
  );

/*Get Featured Track */
export const getFeaturedTrack = () =>
  new Promise((resolve, reject) =>
    axiosInstance
      .get(`tracks/explore/featured`)
      .then((response) => {
        resolve(response.data.data);
      })
      .catch((error) => {
        catchErrorWrong(error, reject);
      })
  );

/*Get Trending Album */
export const getTrendingAlbum = () =>
  new Promise((resolve, reject) =>
    axiosInstance
      .get(`albums/explore/trending`)
      .then((response) => {
        resolve(response.data.data);
      })
      .catch((error) => {
        catchErrorWrong(error, reject);
      })
  );

/*Get Featured Album */
export const getFeaturedAlbum = () =>
  new Promise((resolve, reject) =>
    axiosInstance
      .get(`albums/explore/featured`)
      .then((response) => {
        resolve(response.data.data);
      })
      .catch((error) => {
        catchErrorWrong(error, reject);
      })
  );

export const getPlayList = () =>
  new Promise((resolve, reject) =>
    axiosInstance
      .get(`playlists`)
      .then((response) => {
        const list = response && response?.data ? response?.data : [];
        resolve(list);
      })
      .catch((error) => {
        catchErrorWrong(error, reject);
      })
  );

export const trackToPlaylist = (value) =>
  new Promise((resolve, reject) =>
    axiosInstance
      .post(`playlist-track`, value)
      .then((response) => {
        resolve(response?.data?.data);
        showToast("Successfully !", response?.data?.message, "success");
      })
      .catch((error) => {
        errorLog(error);
        catchErrorShowError(error, reject);
      })
  );

/* Suffle wavesurfer */
export const trackShuffle = (value) =>
  new Promise((resolve, reject) =>
    axiosInstance
      .post(`playlist-track/update-play-order`, value)
      .then((response) => {
        resolve(response?.data?.data);
        // showToast("Successfully !", response?.data?.message, "success");
      })
      .catch((error) => {
        errorLog(error);
        error.response && error.response.data.message
          ? showToast("Failed !", error.response.data.message, "error")
          : reject({ message: `Something Went Wrong` });
      })
  );

/* tracks upload */
export const tracksUpload = (values) =>
  new Promise((resolve, reject) =>
    axiosInstance
      .post(`tracks/uploads`, { tracks: values })
      .then((response) => {
        resolve(response?.data?.data);
        showToast("Successfully !", response?.data?.message, "success");
      })
      .catch((error) => {
        errorLog(error);
        catchErrorShowError(error, reject);
      })
  );

//tracks/generate/presigned-post-url-S3
export const getS3Post = () =>
  new Promise((resolve, reject) =>
    axiosInstance
      .get(`tracks/generate/presigned-post-url-S3`)
      .then((response) => {
        resolve(response?.data?.data);
      })
      .catch((error) => {
        catchErrorWrong(error, reject);
      })
  );

//playlists/pinplaylist/${values?.id}
export const removeUploadedFileFromS3 = (values) =>
  new Promise((resolve, reject) =>
    axiosInstance
      .post(`tracks/remove/file-from-S3`, { tracks: values })
      .then((response) => {
        resolve(response?.data?.data);
      })
      .catch((error) => {
        catchErrorWrong(error, reject);
      })
  );

export const removeAllUploadedFileFromS3 = (values) =>
  new Promise((resolve, reject) =>
    axiosInstance
      .get(`tracks/remove-file-from-S3/${values}`)
      .then((response) => {
        resolve(response?.data?.data);
      })
      .catch((error) => {
        catchErrorWrong(error, reject);
      })
  );

export const availableS3Storage = () =>
  new Promise((resolve, reject) =>
    axiosInstance
      .get(`tracks/storage/info`)
      .then((response) => {
        resolve(response?.data?.data);
      })
      .catch((error) => {
        errorLog(error);
        catchErrorShowError(error, reject);
      })
  );

export const availableCredits = () =>
  new Promise((resolve, reject) =>
    axiosInstance
      .get(`user-credit/get-credit`)
      .then((response) => {
        resolve(response?.data?.data);
      })
      .catch((error) => {
        errorLog(error);
        catchErrorShowError(error, reject);
      })
  );

export const tracksLibraryUpload = () =>
  new Promise((resolve, reject) =>
    axiosInstance
      .get(`tracks/library/uploads`)
      .then((response) => {
        resolve(response?.data?.data);
      })
      .catch((error) => {
        errorLog(error);
        catchErrorShowError(error, reject);
      })
  );

export const deleteTracks = (id) =>
  new Promise((resolve, reject) =>
    axiosInstance
      .delete(`tracks/${id}`)
      .then((response) => {
        resolve(response?.data);
      })
      .catch((error) => {
        errorLog(error);
        catchErrorShowError(error, reject);
      })
  );

export const downloadTracks = async (id, name) => {
  const token1 = getLocal("access_token");
  try {
    const response = await fetch(
      `${process.env.NEXT_PUBLIC_BACKEND_API_URL}tracks/download/single/${id}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "arrayBuffer",
          Authorization: token1,
        },
      }
    );

    return response;
  } catch (error) {
    console.error(error);
  }
};

/* get Search*/
export const getOpenSearch = (value) =>
  new Promise((resolve, reject) =>
    axiosInstance
      .get(`search?query=${value.search}`)
      .then((response) => {
        resolve(response?.data?.data);
      })
      .catch((error) => {
        errorLog(error);
        catchErrorShowError(error, reject);
      })
  );

/* get suggestions */
export const getSuggestions = (value) =>
  new Promise((resolve, reject) =>
    axiosInstance
      .get(`search/suggestions?query= ${value.search}`)
      .then((response) => {
        resolve(response?.data?.data);
      })
      .catch((error) => {
        errorLog(error);
        catchErrorShowError(error, reject);
      })
  );

/* get suggestions */
export const getPopularWord = (value) =>
  new Promise((resolve, reject) =>
    axiosInstance
      .get(`search/popular-word?query=${value.search}`)
      .then((response) => {
        resolve(response?.data?.data);
      })
      .catch((error) => {
        errorLog(error);
        catchErrorShowError(error, reject);
      })
  );

/* Add to cart */
export const postAddToCart = (value) =>
  new Promise((resolve, reject) =>
    axiosInstance
      .post(`cart`, value)
      .then((response) => {
        resolve(response?.data?.message);
        showToast("Successfully !", response?.data?.message, "success");
      })
      .catch((error) => {
        errorLog(error);
        catchErrorShowError(error, reject);
      })
  );

// get cart detail
export const getCartDetail = (value) =>
  new Promise((resolve, reject) =>
    axiosInstance
      .get(`cart`)
      .then((response) => {
        resolve(response?.data?.data);
      })
      .catch((error) => {
        errorLog(error);
        catchErrorShowError(error, reject);
      })
  );

//delete cart detail;
export const deleteCartDetail = (id) =>
  new Promise((resolve, reject) =>
    axiosInstance
      .delete(`cart/${id}`)
      .then((response) => {
        resolve(response?.data?.data);
      })
      .catch((error) => {
        errorLog(error);
        catchErrorShowError(error, reject);
      })
  );


//delete card detail;
export const deleteCard = (id) =>
new Promise((resolve, reject) =>
  axiosInstance
    .delete(`subscription/remove-payment-method/${id}`)
    .then((response) => {
      resolve(response?.data?.data);
    })
    .catch((error) => {
      errorLog(error);
      catchErrorShowError(error, reject);
    })
);

//Post Purchase cart ;
export const getUserPaymentMethod = (value) =>
  new Promise((resolve, reject) =>
    axiosInstance
      .get(`user-payment-methods`, value)
      .then((response) => {
        resolve(response?.data?.data);
      })
      .catch((error) => {
        errorLog(error);
        catchErrorShowError(error, reject);
      })
  );

//Post Purchase cart ;
export const postPurchaseCart = (value) =>
  new Promise((resolve, reject) =>
    axiosInstance
      .post(`purchase`, value)
      .then((response) => {
        resolve(response?.data?.data);
        if (response)
          showToast("Successfully !", response?.data?.message, "success");
      })
      .catch((error) => {
        errorLog(error);
        catchErrorShowError(error, reject);
      })
  );

export const getPaymentFailed = () =>
  new Promise((resolve, reject) =>
    axiosInstance
      .get(`user-payment-methods/total-payments-attempts`)
      .then((response) => {
        resolve(response?.data?.data);
      })
      .catch((error) => {
        errorLog(error);
        catchErrorShowError(error, reject);
      })
  );

//get my library purchase ;
export const getMyLibraryPurchaseTrack = () =>
  new Promise((resolve, reject) =>
    axiosInstance
      .get(`purchase/my-library`)
      .then((response) => {
        resolve(response?.data?.data);
      })
      .catch((error) => {
        errorLog(error);
        catchErrorShowError(error, reject);
      })
  );

/* get Library Upload Search*/
export const getLibraryUploadSearch = (value) =>
  new Promise((resolve, reject) =>
    axiosInstance
      .get(`tracks/library/uploads?search=${value.search}`)
      .then((response) => {
        resolve(response?.data?.data);
      })
      .catch((error) => {
        errorLog(error);
        catchErrorShowError(error, reject);
      })
  );

/* get Purchase Upload Search*/
export const getLibraryPurchaseSearch = (value) =>
  new Promise((resolve, reject) =>
    axiosInstance
      .get(`purchase/my-library?search=${value.search}`)
      .then((response) => {
        resolve(response?.data?.data);
      })
      .catch((error) => {
        errorLog(error);
        catchErrorShowError(error, reject);
      })
  );

/* post Promo code*/
export const postPromoCode = (value) =>
  new Promise((resolve, reject) =>
    axiosInstance
      .post(`cart/apply-promo-code`, value)
      .then((response) => {
        resolve(response?.data?.data);
        if (response?.data?.data)
          showToast("Successfully !", response?.data?.message, "success");
      })
      .catch((error) => {
        reject(error.response.data?.message);
      })
  );

/* Get All Plans*/
export const getAllPlans = () =>
  new Promise((resolve, reject) =>
    axiosInstance
      .get(`plans/active-plan`)
      .then((response) => {
        resolve(response?.data?.data);
      })
      .catch((error) => {
        errorLog(error);
        reject(error?.response?.data?.data?.message);
      })
  );

export const postPaymentIntentService = (data) =>
  new Promise((resolve, reject) =>
    axiosInstance
      .post(`user-payment-methods/payment-intent`, data)
      .then((response) => {
        resolve(response?.data?.data);
      })
      .catch((error) => {
        errorLog(error);
        catchErrorShowError(error, reject);
      })
  );

export const postSetUpPaymentIntentService = (data) =>
  new Promise((resolve, reject) =>
    axiosInstance
      .post(`user-payment-methods/payment-setup-intent`, data)
      .then((response) => {
        resolve(response?.data?.data);
      })
      .catch((error) => {
        errorLog(error);
        catchErrorShowError(error, reject);
      })
  );

/* POST subscription Plans*/
export const postSubscription = (value) =>
  new Promise((resolve, reject) =>
    axiosInstance
      .post(`subscription`, value)
      .then((response) => {
        resolve(response?.data?.data);
      })
      .catch((error) => {
        errorLog(error);
        reject(error.response?.data?.data?.message);
      })
  );

/* GET subscription Plans*/
export const getSubscription = () =>
  new Promise((resolve, reject) =>
    axiosInstance
      .get(`subscription`)
      .then((response) => {
        resolve(response?.data?.data);
      })
      .catch((error) => {
        catchErrorLog(error, reject);
      })
  );

/* POST subscription change-plan */
export const postSubscriptionChangePlan = (value) =>
  new Promise((resolve, reject) =>
    axiosInstance
      .post(`subscription/change-plan`, value)
      .then((response) => {
        resolve(response?.data);
      })
      .catch((error) => {
        catchErrorShowError(error, reject);
      })
  );

/* POST subscription change-plan */
export const postSubscriptionCancelPlan = (value) =>
  new Promise((resolve, reject) =>
    axiosInstance
      .post(`subscription/cancel-plan`, value)
      .then((response) => {
        resolve(response?.data);
      })
      .catch((error) => {
        catchErrorLog(error, reject);
      })
  );

/* POST subscription draft-invoice-selected-plan*/
export const postSubscriptionDraftPlan = (value) =>
  new Promise((resolve, reject) =>
    axiosInstance
      .post(`subscription/draft-invoice-selected-plan`, value)
      .then((response) => {
        if (
          response?.data &&
          response?.data?.coupon_message &&
          response?.data?.coupon_message
        ) {
          showToast(
            "Successfully !",
            response?.data?.coupon_message,
            "success"
          );
        }
        resolve(response?.data);
      })
      .catch((error) => {
        errorLog(error);
        reject(error.response?.data?.message);
      })
  );

/* get Plan By Id*/
export const getPlanById = (id) =>
  new Promise((resolve, reject) =>
    axiosInstance
      .get(`/plans/price/${id}`)
      .then((response) => {
        resolve(response?.data?.data);
      })
      .catch((error) => {
        catchErrorLog(error, reject);
      })
  );

//Plan Available track
export const getPlanAvailableTrack = (id) =>
  new Promise((resolve, reject) =>
    axiosInstance
      .post(`/plans/available-tracks`, id)
      .then((response) => {
        resolve(response?.data?.data);
      })
      .catch((error) => {
        catchErrorLog(error, reject);
      })
  );

//Plan Available track
export const postPlanAvailableTrackSec = (value) =>
  new Promise((resolve, reject) =>
    axiosInstance
      .post(`/tracks/explore/initial-tracks`, value)
      .then((response) => {
        resolve(response?.data?.data);
      })
      .catch((error) => {
        catchErrorLog(error, reject);
      })
  );

export const getStoreSection = (access_tokenSSR, refresh_tokenSSR) => {
  const url = `${process.env.NEXT_PUBLIC_BACKEND_API_URL}store-section`;
  if (access_tokenSSR) {
    return new Promise((resolve, reject) =>
      axios({
        method: "get",
        url: url,
        headers: { Authorization: access_tokenSSR },
      })
        .then((response) => {
          resolve({
            data: response?.data?.data,
            access_token: access_tokenSSR,
          });
        })
        .catch(async (error) => {
          const { config, response } = error;
          const originalRequest = config;
          const status = response && response?.status ? response?.status : "";
          try {
            if (refresh_tokenSSR && status == 401) {
              const nData = await refreshTokenAPICall(
                status,
                originalRequest,
                refresh_tokenSSR
              );
              const newAccessToken = nData?.config?.headers?.Authorization;

              const refResData = nData?.data?.data;
              resolve({ data: refResData, access_token: newAccessToken });
            } else {
              reject("INVALID REFRESH TOKEN");
            }
          } catch (refError) {
            reject(error?.response?.data?.message);
          }
        })
    );
  } else {
    return new Promise((resolve, reject) =>
      axios({
        method: "get",
        url: url,
      })
        .then((response) => {
          resolve({ data: response?.data?.data, access_token: null });
        })
        .catch((error) => {
          errorLog(error);
          redirectFn(error.response);
          // if (error.response?.status === 401) {
          //   return redirectFn(error.response);
          // }
          reject(error?.response?.data?.message);
        })
    );
  }
};

export const getSectionItemDetails = (id) =>
  new Promise((resolve, reject) =>
    axiosInstance
      .get(`/store-section/get-section-item-detail?id=${id}`,)
      .then((response) => {
        resolve(response?.data?.data);
      })
      .catch((error) => {
        catchErrorLog(error, reject);
      })
  );

export const postPlanAvailableAlbumsSec = (value) =>
  new Promise((resolve, reject) =>
    axiosInstance
      .post(`/albums/explore/initial`, value)
      .then((response) => {
        resolve(response?.data?.data);
      })
      .catch((error) => {
        catchErrorLog(error, reject);
      })
  );

export const postInitialTrack = (value, tokenSSR) => {
  const url = `${process.env.NEXT_PUBLIC_BACKEND_API_URL}tracks/explore/initial-tracks`;
  if (tokenSSR) {
    return new Promise((resolve, reject) =>
      axios({
        method: "post",
        url: url,
        headers: { Authorization: tokenSSR },
        data: value,
      })
        .then((response) => {
          resolve(response?.data?.data);
        })
        .catch((error) => {
          const { config, response } = error;
          const originalRequest = config;
          const status = response && response?.status ? response?.status : "";
          refreshTokenAPICall(status, originalRequest);
        })
    );
  } else {
    return new Promise((resolve, reject) =>
      axios({
        method: "post",
        url: url,
        data: value,
      })
        .then((response) => {
          resolve(response?.data?.data);
        })
        .catch((error) => {
          errorLog(error);
          redirectFn(error.response);
          // if (error.response?.status === 401) {
          //   return redirectFn(error.response);
          // }
          reject(error?.response?.data?.message);
        })
    );
  }
};

export const postInitialAlbums = (value, tokenSSR) => {
  const url = `${process.env.NEXT_PUBLIC_BACKEND_API_URL}albums/explore/initial`;
  if (tokenSSR) {
    return new Promise((resolve, reject) =>
      axios({
        method: "post",
        url: url,
        headers: { Authorization: tokenSSR },
        data: value,
      })
        .then((response) => {
          resolve(response?.data?.data);
        })
        .catch((error) => {
          const { config, response } = error;
          const originalRequest = config;
          const status = response && response?.status ? response?.status : "";
          refreshTokenAPICall(status, originalRequest);
        })
    );
  } else {
    return new Promise((resolve, reject) =>
      axios({
        method: "post",
        url: url,
        data: value,
      })
        .then((response) => {
          resolve(response?.data?.data);
        })
        .catch((error) => {
          errorLog(error);
          redirectFn(error.response);
          // if (error.response?.status === 401) {
          //   return redirectFn(error.response);
          // }
          reject(error?.response?.data?.message);
        })
    );
  }
};

export const getAlbumsById = (value, tokenSSR) => {
  const url = `${process.env.NEXT_PUBLIC_BACKEND_API_URL}albums/${value}`;
  if (tokenSSR) {
    return new Promise((resolve, reject) =>
      axios({
        method: "get",
        url: url,
        headers: { Authorization: tokenSSR },
        data: value,
      })
        .then((response) => {
          resolve(response?.data?.data);
        })
        .catch((error) => {
          const { config, response } = error;
          const originalRequest = config;
          const status = response && response?.status ? response?.status : "";
          refreshTokenAPICall(status, originalRequest);
        })
    );
  } else {
    return new Promise((resolve, reject) =>
      axios({
        method: "get",
        url: url,
        data: value,
      })
        .then((response) => {
          resolve(response?.data?.data);
        })
        .catch((error) => {
          errorLog(error);
          redirectFn(error.response);
          // if (error.response?.status === 401) {
          //   return redirectFn(error.response);
          // }
          reject(error?.response?.data?.message);
        })
    );
  }
};

export const getAlbumsBySlug = (value, tokenSSR, refreshSSR) => {
  const url = `${process.env.NEXT_PUBLIC_BACKEND_API_URL}albums/get-album-details/${value}`;
  if (tokenSSR) {
    return new Promise((resolve, reject) =>
      axios({
        method: "get",
        url: url,
        headers: { Authorization: tokenSSR },
      })
        .then((response) => {
          resolve({ data: response?.data?.data, tokenSSR });
        })
        .catch(async (error) => {
          const { config, response } = error;
          const originalRequest = config;
          const status = response && response?.status ? response?.status : "";
          if (status == 401 && refreshSSR) {
            const nData = await refreshTokenAPICall(status, originalRequest, refreshSSR);

            const newAccessToken = nData?.config?.headers?.Authorization;

            const refResData = nData?.data?.data;
            resolve({ data: refResData, tokenSSR: newAccessToken });
          } else {
            reject(error?.response?.data?.message);
          }
        })
    );
  } else {
    return new Promise((resolve, reject) =>
      axios({
        method: "get",
        url: url,
      })
        .then((response) => {
          resolve({ data: response?.data?.data, tokenSSR: null });
        })
        .catch((error) => {
          errorLog(error);
          redirectFn(error.response);
          // if (error.response?.status === 401) {
          //   return redirectFn(error.response);
          // }
          reject(error?.response?.data?.message);
        })
    );
  }
};

export const getAlbumsBySlugByCart = (value) =>
  new Promise((resolve, reject) =>
    axiosInstance
      .get(`/albums/get-album-details/${value}`)
      .then((response) => {
        resolve(response?.data?.data);
      })
      .catch((error) => {
        catchErrorLog(error, reject);
      })
  );

export const getShareTrackByUriName = (uriName, tokenSSR, refreshSSR) => {
  const url = `${process.env.NEXT_PUBLIC_BACKEND_API_URL}tracks/get-track-details-with-album/${uriName}`;
  if (tokenSSR) {
    return new Promise((resolve, reject) =>
      axios({
        method: "get",
        url: url,
        headers: { Authorization: tokenSSR },
      })
        .then((response) => {
          resolve({ data: response?.data?.data, tokenSSR: tokenSSR });
        })
        .catch(async (error) => {
          const { config, response } = error;
          const originalRequest = config;
          const status = response && response?.status ? response?.status : "";
          if (refreshSSR && status == 401) {
            const nData = await refreshTokenAPICall(status, originalRequest, refreshSSR);

            const newAccessToken = nData?.config?.headers?.Authorization;

            const refResData = nData?.data?.data;
            resolve({ data: refResData, tokenSSR: newAccessToken });
          } else {
            reject(error?.response?.data?.message);
          }
        })
    );
  } else {
    return new Promise((resolve, reject) =>
      axios({
        method: "get",
        url: url,
      })
        .then((response) => {
          resolve({ data: response?.data?.data, tokenSSR: null });
        })
        .catch((error) => {
          errorLog(error);
          redirectFn(error.response);
          // if (error.response?.status === 401) {
          //   return redirectFn(error.response);
          // }
          reject(error?.response?.data?.message);
        })
    );
  }
};

export const postActiveInvitation = (value) =>
  new Promise((resolve, reject) =>
    axiosInstance
      .post(`/auth/trial-epic-plan`, value)
      .then((response) => {
        resolve(response);
        showToast("Successfully !", response?.data?.message, "success");
      })
      .catch((error) => {
        catchErrorShowError(error, reject);
      })
  );

const onAccessTokenFetched = (accessToken) => {
  subscribers = subscribers.filter((callback) => callback(accessToken));
};

const addSubscriber = (callback) => {
  subscribers.push(callback);
};

const refreshTokenAPICall = (status, originalRequest, refresh_tokenSSR) => {
  if (status === 401 && refresh_tokenSSR) {
    return commonRefreshFn(originalRequest, refresh_tokenSSR);
  }
};

export const catchErrorLog = (error, reject) => (
  errorLog(error), reject(error.response?.message)
);

export const catchErrorShowError = (error, reject) =>
  error.response && error.response.data.message
    ? showToast("Failed !", error.response.data.message, "error")
    : reject({ message: `Something Went Wrong` });

export const catchErrorWrong = (error, reject) => (
  errorLog(error), reject({ message: `Something Went Wrong` })
);

export const PostSQS = (value) =>
  new Promise((resolve, reject) =>
    axiosInstance
      .post(`/tracks/sqs/event-store`, value)
      .then((response) => {
        resolve(response?.data?.data);
      })
      .catch((error) => {
        catchErrorLog(error, reject);
      })
  );

const commonRefreshFn = (originalRequest, refresh_token) => {
  // Refresh Token Promise
  if (!isAlreadyFetchingAccessToken) {
    isAlreadyFetchingAccessToken = true;
    refreshAccessToken(refresh_token)
      .then((response) => {
        isAlreadyFetchingAccessToken = false;
        // Replacing Tokens
        // removeLocal("access_token");
        // removeLocal("refresh_token");
        // setLocal("access_token", response.data.data.access_token);
        // setLocal("refresh_token", response.data.data.refresh_token);
        // homePageCount();
        return onAccessTokenFetched(response?.data?.data?.access_token);
      })
      .catch((error) => {
        isAlreadyFetchingAccessToken = false;
        // Removing User State
        // removeLocal("access_token");
        // removeLocal("refresh_token");
        // homePageCount();
        // if (
        //   window.location.pathname === "/auth/signin" ||
        //   window.location.pathname === "/auth/forgotpassword"
        // ) {
        //   return;
        // } else {
        //   setTimeout(() => {
        //     window.location.href = "/";
        //   }, 500);
        // }
        return onAccessTokenFetched('');
      });
  }
  const retryOriginalRequest = new Promise((resolve) => {
    return addSubscriber((accessToken) => {
      // Make sure to assign accessToken according to your response.
      // Change Authorization header
      originalRequest.headers["Authorization"] = `${accessToken}`;
      return axios(originalRequest).then((res) => {
        return resolve(res);
      });
    });
  });
  return retryOriginalRequest.then((res) => {
    return res;
  });
};

export const cancelLegacyUserSubscription = () =>
  new Promise((resolve, reject) =>
    axiosInstance
      .get(`/subscription/cancel/legacy-user-subscription`)
      .then((response) => {
        resolve(response?.data?.data);
      })
      .catch((error) => {
        catchErrorLog(error, reject);
      })
  );
