const initialState = {
  tracks: {},
};

export default function common(state = initialState, action) {
  switch (action.type) {
    case "PLAY_TRACK": {
      const playerId = action.payload.playerId;
      return {
        ...state,
        tracks: {
          [playerId]: {
            ...state.tracks[playerId],
            isPlaying: true,
            playerId:playerId
          },
        },
      };
    }

    case "PAUSE_TRACK": {
      const playerId = action.payload.playerId;
      return {
        ...state,
        tracks: {
          [playerId]: {
            ...state.tracks[playerId],
            isPlaying: false,
            playerId:null
          },
        },
      };
    }

    default: {
      return state;
    }
  }
}
