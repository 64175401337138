import { useEffect } from "react";
import "aos/dist/aos.css";
import "../styles/globals.css";
import "../styles/globals.scss";
import "../styles/skeleton-loader.scss"
import "../common/icon/icomoon-css.css";
import { Provider, useSelector } from "react-redux";
import { ToastContainer } from "react-toastify";
import TagManager from "react-gtm-module";
import CacheBuster from "react-cache-buster";
import LoadingBar from "react-top-loading-bar";
import { Router, useRouter } from "next/router";
// import Header_seo from "./head-seo";
import dynamic from "next/dynamic";
import Header_Seo from "./head-seo-final";
const Head_seo = dynamic(() => import("./head-seo"), {
  ssr: true,
});

import useStore from "../redux-store";
import WaveSurfer from "../higher-order-components/wavesurfe/wave";
import useWindowDimensions from "../common/function/hooks/use-window-dimensions";
import ButtonNavigation from "../component/button-navigation/index";
import {
  checkDeviceComparability,
  deleteFinalCookie,
  onlineOfflineStatus,
} from "../common/function/function";
import { initLog } from "../common/sentry";
import useBuildId, {
  useReloadOnNewBuild,
} from "../common/function/hooks/useBuildId";
import PackageJson from "../package.json";
import { deleteCookie, getCookie, getLocal } from "../common/function/storage";
const { store } = useStore();

function MyApp({ Component, pageProps }) {
  const _TOKEN = getCookie("access_token");
  const _REFRESH_TOKEN = getCookie("refresh_token");
  const router = useRouter();
  const { shouldReload } = useReloadOnNewBuild();

  const tagManagerArgs = {
    gtmId: process?.env?.NEXT_PUBLIC_GTM_KEY,
    events: {
      "gtm.start": new Date().getTime(),
      event: "gtm.js",
    },
  };

  useEffect(() => {
    if (process.env.NEXT_PUBLIC_IS_UNDER_MAINTENANCE == "true") {
      router.push("/maintenance");
    }
  }, []);

  useEffect(() => {
    if (process.env.NEXT_PUBLIC_ENVIRONMENT === "production") {
      TagManager.initialize(tagManagerArgs);
      initLog(PackageJson?.version);
      // console.log=()=> {}
      // console.warn=()=> {}
      // console.error=()=> {}
    }
  }, []);

  useEffect(() => {
    onlineOfflineStatus();
    if(!_TOKEN && !_REFRESH_TOKEN){
      deleteFinalCookie()
      localStorage.clear();
    }
  }, []);

  useEffect(() => {
    if (shouldReload) {
      window.location.reload();
    }
  }, [shouldReload]);

  
  const isProduction = process.env.NODE_ENV === "production";
  const getLayout = Component.getLayout
 
  return (
    <>
    <Head_seo {...pageProps.headProps}/>
    <CacheBuster
      currentVersion={PackageJson?.version}
      isEnabled={isProduction} //If false, the library is disabled.
      isVerboseMode={true} //If true, the library writes verbose logs to console.
      loadingComponent={<LoadingBar color="#dcba6c" progress={0} height={4} />} //If not pass, nothing appears at the time of new version check.
      metaFileDirectory={"."} //If public assets are hosted somewhere other than root on your server.
    >
           <Provider store={store}>
        <Header_Seo />
        {/* <Component {...pageProps} /> */}
        {getLayout ? (
          getLayout(<Component {...pageProps} />)
        ) : (
          <Component {...pageProps} />
        )}
        {/* {getLayout(<Component {...pageProps} />)} */}

        <WaveSurferWrapper />
        <ToastContainer autoClose={500} />
        {router?.pathname?.split('/')[1] !== 'embedded-player' ? <FooterButtonNavigation /> : null}
      </Provider>
    </CacheBuster>
    </>
  );
}

const WaveSurferWrapper = () => {
  const _AUDIO_QUALITY_MODAL = useSelector(
    (state) => state.common?.audioQualityModal
  );

  return !_AUDIO_QUALITY_MODAL ? <WaveSurfer /> : null;
};

const FooterButtonNavigation = () => {
  const { width } = useWindowDimensions();
  let isMobile = width <= 767;
  return isMobile && checkDeviceComparability() ? <ButtonNavigation /> : null;
};

export default MyApp;
