import * as Actions from "../actions/auth/index";
import * as Explore from "../actions/albums/index";

const initialState = {
  authStore: {},
  currentPlayData: {
    isMultiplePlaying: false,
    currentPlayingIndex: 0,
    library: false,
    tracksData: [],
  },
  playIDWaveIndex: 0,
  albumPlayAll: false,
  openAlbumsDetail: "",
  counter: 0,
  openAccountDetail: false,
  uploadMusicModel: false,
  searchValue: "",
  albumsTypes: null,
  playList: [],
  parentFileUpload: {},
  currentFileData: [],
  currentFileDataWave:{
    originalname: "",
    size: "",
    filename: "",
    peaks: "",
    duration: "",
  },
  fileUploadError: false,
  removeAllFile: [],
  uploadFileProgress: "",
  uploadTracksHeaderData: [],
  getStorageData: {},
  headerProgress: "",
  myLibraryTabs: "",
  selectTrackFlag: false,
  selectedTracksIds: [],
  playingState: false,
  waveSurferObject: "",
  audioQualityModal: false,
  openMoreMenu: false,
  isMinimizedFileUploadModal: false,
  uploadFileLength: [],
  fileUploadAllError: false,
  onEnterKeyFlag: false,
  openMobileSearch: false,
  cartDropDownToggle: false,
  purchaseSelectedTracksIds: [],
  selectPurchaseTrackFlag: false,
  promoCodeString: "",
  creditAvailableApply:false,
  downloadTimeId: [],
  selectPurchaseTrackName: "",
  planWarningModalFlag: false,
  selectedPaymentPlan: "Annually",
  cancelPlanFlag: false,
  accountSubscriptionFlag: false,
  purchaseDownloadTimeId: [],
  deleteUploadedTrack: false,
  activeIndex: false,
  purchaseCount:0,
  searchSelectActiveTrack:''
};

export default function counterReducer(state = initialState, action) {
  switch (action.type) {
    // LOGGING USER
    case Actions.POST_LOGIN: {
      return {
        ...state,
        authStore: action?.payload,
        loading: false,
      };
    }
    case "ALBUM_PLAY_ALL": {
      return {
        ...state,
        albumPlayAll: action?.payload,
      };
    }
    case "COUNTER_INCREMENT_ALBUM": {
      return {
        ...state,
        counter: action?.payload,
      };
    }
    case "OPEN_ALBUMS_DETAIL": {
      return {
        ...state,
        openAlbumsDetail: action?.payload,
      };
    }
    case "CURRENT_PLAY_DATA": {
      return {
        ...state,
        currentPlayData: action?.payload,
      };
    }
    case "PLAY_ID_WAVE_INDEX": {
      return {
        ...state,
        playIDWaveIndex: action?.payload,
      };
    }

    case "OPEN_ACCOUNT_DETAIL": {
      return {
        ...state,
        openAccountDetail: action?.payload,
      };
    }
    case "UPLOAD_MUSIC_MODAL": {
      return {
        ...state,
        uploadMusicModal: action?.payload,
      };
    }
    /*   case "ALBUMS_TYPE": {
      return {
        ...state,
        albumsTypes: action?.payload,
      };
    } */
    case "SEARCH_VALUE": {
      return {
        ...state,
        searchValue: action?.payload,
      };
    }
    case "ON_CLICK_SEARCH_HISTORY": {
      return {
        ...state,
        onClickSearchHistory: action?.payload,
      };
    }

    case Explore.GET_PLAYLIST: {
      return {
        ...state,
        playList: action?.payload,
        loader: false,
      };
    }
    case "PARENT_FILE_UPLOAD": {
      return {
        ...state,
        parentFileUpload: action?.payload,
      };
    } //
    case "CURRENT_FILE_DATA": {
      return {
        ...state,
        currentFileData: [...state.currentFileData, ...action.payload],
      };
    }
    case "CURRENT_FILE_DATA_WAVE": {
      return {
        ...state,
        currentFileDataWave: {...state.currentFileDataWave, ...action.payload},
      };
    }
    case "CURRENT_FILE_DATA_CHANGE": {
      return {
        ...state,
        currentFileData: action.payload,
      };
    }
    case "FILE_UPLOAD_ERROR": {
      return {
        ...state,
        fileUploadError: action.payload,
      };
    }
    case "REMOVE_ALL_FILE": {
      return {
        ...state,
        removeAllFile: [...state.removeAllFile, ...action.payload],
      };
    }
    case "PLAYING_STATE": {
      return {
        ...state,
        playingState: action.payload,
      };
    }

    case "UPLOAD_TRACKS_HEADER_DATA": {
      return {
        ...state,
        uploadTracksHeaderData: [
          ...state.uploadTracksHeaderData,
          ...action.payload,
        ],
      };
    }
    case "UPLOAD_TRACKS_HEADER_DATA_CHANGE": {
      return {
        ...state,
        uploadTracksHeaderData: action.payload,
      };
    }
    case "UPLOAD_TRACKS_PROGRESS": {
      return {
        ...state,
        uploadFileProgress: action.payload,
      };
    }
    case "FILE_UPLOAD_ALL_ERROR": {
      return {
        ...state,
        fileUploadAllError: action.payload,
      };
    }
    case "DELETE_UPLOADED_TRACK": {
      return {
        ...state,
        deleteUploadedTrack: action.payload,
      };
    }
    case "HEADER_PROGRESS": {
      return {
        ...state,
        headerProgress: action.payload,
      };
    }
    case "OPEN_FILE_UPLOAD_MODAL": {
      return { ...state, FileUploadModal: action.payload };
    }
    case "MINIMIZED_FILE_UPLOAD_MODAL": {
      return { ...state, isMinimizedFileUploadModal: action.payload };
    }
    case "UPLOAD_FILE_LENGTH": {
      return {
        ...state,
        uploadFileLength: action.payload,
      };
    }
    case "MY_LIBRARY_TABS": {
      return {
        ...state,
        myLibraryTabs: action.payload,
      };
    }

    case "SELECT_TRACK_FLAG": {
      return {
        ...state,
        selectTrackFlag: action.payload,
      };
    }
    case "SELECTED_TRACKS_IDS": {
      let data = [...state.selectedTracksIds];
      data.push(action?.payload);

      return {
        ...state,
        selectedTracksIds: data,
      };
    }
    case "REMOVE_SELECTED_TRACKS_IDS": {
      let data = state?.selectedTracksIds?.findIndex(
        (item) => item === action?.payload
      );

      state?.selectedTracksIds?.splice(data, 1);
      return {
        ...state,
        selectedTracksIds: [...state?.selectedTracksIds],
      };
    }
    case "REMOVE_SELECTED_TRACKS_IDS_CHANGE": {
      return {
        ...state,
        selectedTracksIds: action.payload,
      };
    }
    case Explore.GET_AVAILABLE_STORAGE: {
      return {
        ...state,
        getStorageData: action?.payload,
      };
    }
    case Explore.GET_AVAILABLE_CREDITS: {
      return {
        ...state,
        getCreditsData: action?.payload,
      };
    }
    case "WAVE_SURFER": {
      return {
        ...state,
        waveSurferObject: action?.payload,
      };
    }

    case "AUDIO_QUALITY_MODAL": {
      return {
        ...state,
        audioQualityModal: action?.payload,
      };
    }

    case "OPEN_MORE_MENU": {
      return {
        ...state,
        openMoreMenu: action?.payload,
      };
    }

    case "ON_ENTER_KEY_FLAG": {
      return {
        ...state,
        onEnterKeyFlag: action?.payload,
      };
    }
    case "OPEN_MOBILE_SEARCH": {
      return {
        ...state,
        openMobileSearch: action?.payload,
      };
    }
    case "CART_DROP_DOWN_TOGGLE": {
      return {
        ...state,
        cartDropDownToggle: action?.payload,
      };
    }
    case "SELECT_PURCHASE_TRACK_FLAG": {
      return {
        ...state,
        selectPurchaseTrackFlag: action?.payload,
      };
    }
    case "SELECT_PURCHASE_TRACK_NAME": {
      return {
        ...state,
        selectPurchaseTrackName: action?.payload,
      };
    }
    case "PURCHASE_SELECTED_TRACKS_IDS": {
      return {
        ...state,
        purchaseSelectedTracksIds: action?.payload,
      };
    }
    case "PLAN_WARNING_MODEL_FLAG": {
      return {
        ...state,
        planWarningModalFlag: action?.payload,
      };
    }
    case "PROMO_CODE_STRING": {
      return {
        ...state,
        promoCodeString: action?.payload,
      };
    }
    case "CREDIT_APPLY": {
      return {
        ...state,
        creditAvailableApply: action?.payload,
      };
    }
    //push data in state?
    case "DOWNLOAD_TIME_ID": {
      let data = [];
      data = [...state.downloadTimeId, action.payload];

      return {
        ...state,
        downloadTimeId: data,
      };
    }
    case "REMOVE_DOWNLOAD_TIME_ID": {
      let data = state?.downloadTimeId?.findIndex(
        (item) => item === action?.payload
      );

      state?.downloadTimeId?.splice(data, 1);
      return {
        ...state,
        selectedTracksIds: [...state?.downloadTimeId],
      };
    }
    case "SELECTED_PAYMENT_PLAN": {
      return {
        ...state,
        selectedPaymentPlan: action?.payload,
      };
    }
    case "CANCEL_PLAN_FLAG": {
      return {
        ...state,
        cancelPlanFlag: action?.payload,
      };
    }
    case "ACCOUNT_SUBSCRIPTION_FLAG": {
      return {
        ...state,
        accountSubscriptionFlag: action?.payload,
      };
    }

    case "PURCHASE_DOWNLOAD_TIME_ID": {
      let data = [];
      data = [...state.purchaseDownloadTimeId, action.payload];

      return {
        ...state,
        purchaseDownloadTimeId: data,
      };
    }
    case "PURCHASE_REMOVE_DOWNLOAD_TIME_ID": {
      let data = state?.purchaseDownloadTimeId?.findIndex(
        (item) => item === action?.payload
      );

      state?.purchaseDownloadTimeId?.splice(data, 1);
      return {
        ...state,
        purchaseDownloadTimeId: [...state?.purchaseDownloadTimeId],
      };
    }
    case "ACTIVE_INDEX": {
      return {
        ...state,
        activeIndex: action?.payload,
      };
    }
    case "SEARCH_SELECT_ACTIVE_TRACK": {
      return {
        ...state,
        searchSelectActiveTrack: action?.payload,
      };
    }

    
    default:
      return { ...state };
  }
}
