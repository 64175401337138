import { getLocal } from "../../../common/function/storage";
import {
  getAlbums,
  getAlbumsById,
  getFilerAlbums,
  getAudioTypes,
  getPlayTimeUrl,
  getAudioAllDownload,
  getFeaturedTrack,
  getTrendingTrack,
  getFeaturedAlbum,
  getTrendingAlbum,
  getPlayList,
  trackToPlaylist,
  tracksUpload,
  removeUploadedFileFromS3,
  removeAllUploadedFileFromS3,
  availableS3Storage,
  tracksLibraryUpload,
  deleteTracks,
  getOpenSearch,
  getSuggestions,
  getPopularWord,
  postAddToCart,
  getCartDetail,
  deleteCartDetail,
  postPurchaseCart,
  getMyLibraryPurchaseTrack,
  userPaymentMethod,
  getLibraryUploadSearch,
  getLibraryPurchaseSearch,
  getUserPaymentMethod,
  postPromoCode,
  getAllPlans,
  postSubscription,
  getSubscription,
  postSubscriptionChangePlan,
  postSubscriptionCancelPlan,
  postSubscriptionDraftPlan,
  getPlanById,
  getPlanAvailableTrack,
  postInitialTrack,
  postInitialAlbums,
  postPaymentIntentService,
  postSetUpPaymentIntentService,
  getShareTrackByUriName,
  getAlbumsBySlug,
  getPaymentFailed,
  postActiveInvitation,
  postPlanAvailableTrackSec,
  postPlanAvailableAlbumsSec,
  generatePeekAPI,
  PostSQS,
  availableCredits,
  trackShuffle,
  getAlbumsBySlugByCart,
  cancelLegacyUserSubscription,
  getStoreSection,
  getSectionItemDetails,
  deleteCard,
} from "../../../services/albums/index";

export const POST_AUDIO_TYPES_LOAD = "POST_AUDIO_TYPES_LOAD";
export const POST_AUDIO_TYPES_ERROR = "POST_AUDIO_TYPES_ERROR";

export const POST_ALBUMS_LOAD = "[ALBUMS] POST_ALBUMS_LOAD";
export const POST_ALBUMS_FILTER_LOAD = "[ALBUMS] POST_ALBUMS_FILTER_LOAD";
export const POST_ALBUMS_FILTER_ERROR = "[ALBUMS] POST_ALBUMS_FILTER_ERROR";

export const POST_INITIAL_ALBUMS_LOAD = "[ALBUMS] POST_INITIAL_ALBUMS_LOAD";
export const POST_INITIAL_ALBUMS_ERROR = "[ALBUMS] POST_INITIAL_ALBUMS_ERROR";

export const POST_INITIAL_TRACK_LOAD = "[ALBUMS] POST_INITIAL_TRACK_LOAD";
export const POST_INITIAL_TRACK_ERROR = "[ALBUMS] POST_INITIAL_TRACK_ERROR";

export const GET_ALBUMS = "[ALBUMS] GET_ALBUMS";
export const GET_ALBUMS_BY_ID = "[ALBUMS] GET_ALBUMS_BY_ID";
export const GET_ALBUMS_FILTER = "[ALBUMS] GET_ALBUMS_FILTER";
export const GET_AUDIO_TYPES = "[ALBUMS] GET_AUDIO_TYPES";
export const GET_TRENDING_TRACK = "[ALBUMS] GET_TRENDING_TRACK";
export const GET_TRENDING_ALBUM = "[ALBUMS] GET_TRENDING_ALBUM";
export const GET_FEATURED_TRACK = "[ALBUMS] GET_FEATURED_TRACK";
export const GET_FEATURED_ALBUM = "[ALBUMS] GET_FEATURED_ALBUM";

export const GET_PLAYLIST = "[ALBUMS] GET_PLAYLIST";

export const POST_ALBUMS_ERROR = "[ALBUMS] POST_ALBUMS_ERROR";

export const GET_PLAY_TIME_URL_LOAD = "[PLAYTIMEURL] GET_PLAY_TIME_URL_LOAD";
export const GET_PLAY_TIME_URL = "[PLAYTIMEURL] GET_PLAY_TIME_URL";
export const GET_PLAY_TIME_URL_LOAD_ERROR =
  "[PLAYTIMEURL] GET_PLAY_TIME_URL_LOAD_ERROR";

export const POST_TRACKS_UPLOAD = "[SECTION] POST_TRACKS_UPLOAD";
export const GET_AVAILABLE_STORAGE = "GET_AVAILABLE_STORAGE";
export const GET_AVAILABLE_CREDITS = "GET_AVAILABLE_CREDITS";

export const GET_TRACKS_LIBRARY_UPLOAD_ALBUM =
  "GET_TRACKS_LIBRARY_UPLOAD_ALBUM";

export const DELETE_TRACKS_LIBRARY_UPLOAD_ALBUM =
  "DELETE_TRACKS_LIBRARY_UPLOAD_ALBUM";

export const GET_OPEN_FILTER_RESPONSE = "[ALBUMS] GET_OPEN_FILTER_RESPONSE";
export const GET_SUGGESTION_RESPONSE = "[ALBUMS] GET_SUGGESTION_RESPONSE";
export const GET_POPULAR_WORD_RESPONSE = "[ALBUMS] GET_POPULAR_WORD_RESPONSE";
// Cart
export const POST_ADD_TO_CART = "[ALBUMS] POST_ADD_TO_CART";
export const GET_CART_DETAIL = "[ALBUMS] GET_CART_DETAIL";
export const GET_ALL_CART_DETAIL = "[ALBUMS] GET_ALL_CART_DETAIL";
export const DELETE_CART_DETAIL = "[ALBUMS] DELETE_CART_DETAIL";
export const DELETE_CARD_DETAIL = "[ALBUMS] DELETE_CARD_DETAIL";
export const GET_MY_LIBRARY_PURCHASE_TRACK =
  "[ALBUMS] GET_MY_LIBRARY_PURCHASE_TRACK";

//LIBRARY SEARCH
export const GET_LIBRARY_UPLOAD_SEARCH_RESPONSE =
  "[ALBUMS] GET_LIBRARY_UPLOAD_SEARCH_RESPONSE";
export const GET_LIBRARY_PURCHASE_SEARCH_RESPONSE =
  "[ALBUMS] GET_LIBRARY_PURCHASE_SEARCH_RESPONSE";
/* get All Albums */

//promo codes
export const POST_PROMO_CODES = "[ALBUMS] POST_PROMO_CODES";
export const POST_PROMO_CODES_ERROR = "[ALBUMS] POST_PROMO_CODES_ERROR";

//PURCHASE
export const TOTAL_ATTEMPTS_CHECK = "[ALBUMS] TOTAL_ATTEMPTS_CHECK";
export const POST_PURCHASE_CART = "[ALBUMS] POST_PURCHASE_CART";
export const GET_PAYMENT_INTENT = "[ALBUMS] GET_PAYMENT_INTENT";
//Plans
export const GET_ALL_PLANS = "[ALBUMS] GET_ALL_PLANS";
export const GET_PLAN_BY_ID = "[ALBUMS] GET_PLAN_BY_ID";
export const GET_PLAN_AVAILABLE_TRACK = "[ALBUMS] GET_PLAN_AVAILABLE_TRACK";

//SUBSCRIPTION
export const POST_SUBSCRIPTION = "[ALBUMS] POST_SUBSCRIPTION";
export const GET_SUBSCRIPTION = "[ALBUMS] GET_SUBSCRIPTION";
export const POST_SUBSCRIPTION_CHANGE_PLAN =
  "[ALBUMS] POST_SUBSCRIPTION_CHANGE_PLAN";
export const POST_SUBSCRIPTION_CANCEL_PLAN =
  "[ALBUMS] POST_SUBSCRIPTION_CANCEL_PLAN";

export const POST_SUBSCRIPTION_DRAFT_PLAN =
  "[ALBUMS] POST_SUBSCRIPTION_DRAFT_PLAN";

export const GET_STORE_SECTION = "[ALBUMS] GET_STORE_SECTION";

export const GET_DYNAMIC_STORE_LOADER = "[ALBUMS] GET_DYNAMIC_STORE_LOADER";

export const GET_DYNAMIC_STORE_ERROR = "[ALBUMS] GET_DYNAMIC_STORE_ERROR";

export const GET_STORE_SECTION_ITEM_DETAIL =
  "[ALBUMS] GET_STORE_SECTION_ITEM_DETAIL";

export const GET_DYNAMIC_STORE_SECTION_ITEM_DETAIL_LOADER =
  "[ALBUMS] GET_DYNAMIC_STORE_SECTION_ITEM_DETAIL_LOADER";

export const GET_DYNAMIC_STORE_SECTION_ITEM_DETAIL_ERROR =
  "[ALBUMS] GET_DYNAMIC_STORE_SECTION_ITEM_DETAIL_ERROR";

// Track management

// loadings
export const ALBUMS_LOADING = "[LOADING] ALBUMS_LOADING";

const setLoadingType = (dispatch, track_category, value) => {
  switch (track_category) {
    case "newTrack":
      {
        dispatch({
          type: ALBUMS_LOADING,
          payload: { getInitialTrackLoading: value },
        });
      }
      break;
    case "popular":
      {
        dispatch({
          type: ALBUMS_LOADING,
          payload: { popularTrackLoading: value },
        });
      }
      break;
    case "mustHaves":
      {
        dispatch({
          type: ALBUMS_LOADING,
          payload: { mustHaveTrackLoading: value },
        });
      }
      break;
    case "trendingByPlays":
      {
        dispatch({
          type: ALBUMS_LOADING,
          payload: { trendingByPlaysTrackLoading: value },
        });
      }
      break;
    case "trendingByPurchases":
      {
        dispatch({
          type: ALBUMS_LOADING,
          payload: { trendingByPurchaseTrackLoading: value },
        });
      }
      break;
  }
};

export const GET_NEW_INITIAL_TRACK = "[ALBUMS] GET_NEW_INITIAL_TRACK";
export function handleAlbums() {
  return async (dispatch) => {
    try {
      dispatch({
        type: POST_ALBUMS_LOAD,
        payload: true,
      });
      const response = await getAlbums();
      return response;
    } catch (error) {
      dispatch({
        type: POST_ALBUMS_ERROR,
        payload: { errorMessage: error.message },
      });
    }
  };
}

/* get Albums by Id*/
export function handleAlbumsById(value, tokenSSR) {
  return async (dispatch) => {
    try {
      // dispatch({
      //   type: POST_ALBUMS_LOAD,
      //   payload: true,
      // });
      const response = await getAlbumsById(value, tokenSSR);

      dispatch({
        type: "GET_ALBUMS_BY_ID",
        payload: response,
      });
      return response;
    } catch (error) {
      dispatch({
        type: ALBUMS_LOADING,
        payload: { albumsByIdLoading: false },
      });
      // dispatch({
      //   type: POST_ALBUMS_ERROR,
      //   payload: { errorMessage: error?.message },
      // });
    }
  };
}

/* get Albums by Slug*/
export function handleAlbumsBySlug(value, tokenSSR, refreshSSR) {
  return async (dispatch) => {
    try {
      dispatch({
        type: POST_ALBUMS_LOAD,
        payload: true,
      });
      const response = await getAlbumsBySlug(value, tokenSSR, refreshSSR);
      dispatch({
        type: "GET_ALBUMS_BY_SLUG",
        payload: response,
      });
      return response;
    } catch (error) {
      dispatch({
        type: POST_ALBUMS_ERROR,
        payload: { errorMessage: error?.message },
      });
    }
  };
}

export function handleAlbumsBySlugByCart(value) {
  return async (dispatch) => {
    try {
      dispatch({
        type: POST_ALBUMS_LOAD,
        payload: true,
      });
      const response = await getAlbumsBySlugByCart(value);
      dispatch({
        type: "GET_ALBUMS_BY_SLUG",
        payload: response,
      });
      return response;
    } catch (error) {
      dispatch({
        type: POST_ALBUMS_ERROR,
        payload: { errorMessage: error?.message },
      });
    }
  };
}

/* get Albums Filter*/
export function handleAlbumsFilter(value) {
  return async (dispatch) => {
    try {
      dispatch({
        type: POST_ALBUMS_FILTER_LOAD,
        payload: true,
      });
      const response = await getFilerAlbums(value);
      dispatch({
        type: "GET_ALBUMS",
        payload: response,
      });
      return response;
    } catch (error) {
      dispatch({
        type: POST_ALBUMS_FILTER_ERROR,
        payload: { errorMessage: error.message },
      });
      return error;
    }
  };
}
/* get All Albums */
export function handleAudioTypes() {
  return async (dispatch) => {
    try {
      dispatch({
        type: POST_AUDIO_TYPES_LOAD,
        payload: true,
      });
      const response = await getAudioTypes();
      dispatch({
        type: "GET_AUDIO_TYPES",
        payload: response,
      });
      return response;
    } catch (error) {
      dispatch({
        type: POST_AUDIO_TYPES_ERROR,
        payload: true,
      });
    }
  };
}

export function handlePlayTimeUrl(value, flagEmbedded) {
  return async (dispatch) => {
    try {
      dispatch({
        type: GET_PLAY_TIME_URL_LOAD,
        payload: true,
      });
      let is_home = false;

      if (typeof window !== "undefined") {
        is_home = window.location.pathname === "/";
      }
      const response = await getPlayTimeUrl(value, flagEmbedded, is_home);
      dispatch({
        type: GET_PLAY_TIME_URL,
        payload: response,
      });
      return response;
    } catch (error) {
      dispatch({
        type: GET_PLAY_TIME_URL_LOAD_ERROR,
        payload: { errorMessage: error.message },
      });
    }
  };
}

//Generated Peaks
export function handleGeneratePeeks(value) {
  return async (dispatch) => {
    try {
      dispatch({
        type: GET_PLAY_TIME_URL_LOAD,
        payload: true,
      });
      const response = await generatePeekAPI(value);
      return response;
    } catch (error) {
      dispatch({
        type: GET_PLAY_TIME_URL_LOAD_ERROR,
        payload: { errorMessage: error.message },
      });
    }
  };
}
/* get All Albums Download*/
export function handleAudioAllDownload(value, name) {
  return async (dispatch) => {
    try {
      dispatch({
        type: POST_ALBUMS_LOAD,
        payload: true,
      });
      const response = await getAudioAllDownload(value, name);

      return response;
    } catch (error) {
      dispatch({
        type: POST_ALBUMS_ERROR,
        payload: { errorMessage: error.message },
      });
    }
  };
}

/* get Albums Treding Track*/
export function handleTrendingTrack(token) {
  return async (dispatch) => {
    try {
      dispatch({
        type: POST_ALBUMS_LOAD,
        payload: true,
      });
      const response = await getTrendingTrack(token);
      return response;
    } catch (error) {
      dispatch({
        type: POST_ALBUMS_ERROR,
        payload: { errorMessage: error.message },
      });
    }
  };
}

/* Get Featured Track */
export function handleFeaturedTrack() {
  return async (dispatch) => {
    try {
      dispatch({
        type: POST_ALBUMS_LOAD,
        payload: true,
      });
      const response = await getFeaturedTrack();
      return response;
    } catch (error) {
      dispatch({
        type: POST_ALBUMS_ERROR,
        payload: { errorMessage: error.message },
      });
    }
  };
}

/* get Albums Trending Album*/
export function handleTrendingAlbum() {
  return async (dispatch) => {
    try {
      dispatch({
        type: POST_ALBUMS_LOAD,
        payload: true,
      });
      const response = await getTrendingAlbum();
      return response;
    } catch (error) {
      dispatch({
        type: POST_ALBUMS_ERROR,
        payload: { errorMessage: error.message },
      });
    }
  };
}

/* Get Featured Album */
export function handleFeaturedAlbum() {
  return async (dispatch) => {
    try {
      dispatch({
        type: POST_ALBUMS_LOAD,
        payload: true,
      });
      const response = await getFeaturedAlbum();
      return response;
    } catch (error) {
      dispatch({
        type: POST_ALBUMS_ERROR,
        payload: { errorMessage: error.message },
      });
    }
  };
}

/**
 * It's a function that returns a function that dispatches an action that returns a function that
 * dispatches an action that returns a function that dispatches an action.
 * @param values - {
 * @returns an object with a type and a payload.
 */
export function handlePlayList() {
  return async (dispatch) => {
    try {
      dispatch({
        type: POST_ALBUMS_LOAD,
        payload: true,
      });
      getPlayList()
        .then((response) => {
          dispatch({
            type: GET_PLAYLIST,
            payload: response,
          });
        })
        .catch((error) => {});
    } catch (error) {
      dispatch({
        type: POST_ALBUMS_ERROR,
        payload: { errorMessage: error.message },
      });
    }
  };
}

export function handleTrackShuffle(values) {
  return async (dispatch) => {
    try {
      dispatch({
        type: POST_ALBUMS_LOAD,
        payload: true,
      });
      const response = await trackShuffle(values);
      return response;
    } catch (error) {
      dispatch({
        type: POST_ALBUMS_ERROR,
        payload: { errorMessage: error.message },
      });
    }
  };
}

export function handleTrackToPlaylist(values) {
  return async (dispatch) => {
    try {
      dispatch({
        type: POST_ALBUMS_LOAD,
        payload: true,
      });
      const response = await trackToPlaylist(values);

      return response;
    } catch (error) {
      dispatch({
        type: POST_ALBUMS_ERROR,
        payload: { errorMessage: error.message },
      });
    }
  };
}

//FILE UPLOAD
export function handleTracksUpload(values) {
  return async (dispatch) => {
    try {
      dispatch({
        type: POST_ALBUMS_LOAD,
        payload: true,
      });

      const response = await tracksUpload(values);
      dispatch({
        type: POST_TRACKS_UPLOAD,
        payload: response,
      });
      return response;
    } catch (error) {
      dispatch({
        type: POST_ALBUMS_ERROR,
        payload: { errorMessage: error.message },
      });
    }
  };
}

export function handleRemoveUploadedFile(values) {
  return async (dispatch) => {
    try {
      dispatch({
        type: POST_ALBUMS_LOAD,
        payload: true,
      });
      const response = await removeUploadedFileFromS3(values);
      dispatch({
        type: POST_TRACKS_UPLOAD,
        payload: response,
      });
    } catch (error) {
      dispatch({
        type: POST_ALBUMS_ERROR,
        payload: { errorMessage: error.message },
      });
    }
  };
}
//remove tracks from file upload
export function handleRemoveAllUploadedFile(values) {
  return async (dispatch) => {
    try {
      dispatch({
        type: POST_ALBUMS_LOAD,
        payload: true,
      });
      const response = await removeAllUploadedFileFromS3(values);
      dispatch({
        type: POST_TRACKS_UPLOAD,
        payload: response,
      });
    } catch (error) {
      dispatch({
        type: POST_ALBUMS_ERROR,
        payload: { errorMessage: error.message },
      });
    }
  };
}

export function handleAvailableStorage() {
  return async (dispatch) => {
    try {
      dispatch({
        type: POST_ALBUMS_LOAD,
        payload: true,
      });
      const response = await availableS3Storage();
      dispatch({
        type: GET_AVAILABLE_STORAGE,
        payload: response,
      });
    } catch (error) {
      dispatch({
        type: POST_ALBUMS_ERROR,
        payload: { errorMessage: error.message },
      });
    }
  };
}

export function handleAvailableCredits() {
  return async (dispatch) => {
    try {
      dispatch({
        type: POST_ALBUMS_LOAD,
        payload: true,
      });
      const response = await availableCredits();
      dispatch({
        type: GET_AVAILABLE_CREDITS,
        payload: response,
      });
    } catch (error) {
      dispatch({
        type: POST_ALBUMS_ERROR,
        payload: { errorMessage: error.message },
      });
    }
  };
}

export function handleTracksLibraryUpload() {
  return async (dispatch) => {
    try {
      dispatch({
        type: ALBUMS_LOADING,
        payload: { tracksLibraryUploadAlbumsLoading: true },
      });
      const response = await tracksLibraryUpload();
      dispatch({
        type: "GET_TRACKS_LIBRARY_UPLOAD_ALBUM",
        payload: response,
      });
    } catch (error) {
      dispatch({
        type: ALBUMS_LOADING,
        payload: { tracksLibraryUploadAlbumsLoading: false },
      });
    }
  };
}

export function handleTracksDelete(id) {
  return async (dispatch) => {
    try {
      dispatch({
        type: POST_ALBUMS_LOAD,
        payload: true,
      });
      const response = await deleteTracks(id);
      dispatch({
        type: DELETE_TRACKS_LIBRARY_UPLOAD_ALBUM,
        payload: response,
      });
      return response;
    } catch (error) {
      dispatch({
        type: POST_ALBUMS_ERROR,
        payload: { errorMessage: error.message },
      });
    }
  };
}
/* get Search*/
export function handleOpenFilter(value) {
  return async (dispatch) => {
    try {
      dispatch({
        type: ALBUMS_LOADING,
        payload: { openFilterSearchLoading: true },
      });
      const response = await getOpenSearch(value);
      dispatch({
        type: GET_OPEN_FILTER_RESPONSE,
        payload: response,
      });
      return response;
    } catch (error) {
      dispatch({
        type: ALBUMS_LOADING,
        payload: { openFilterSearchLoading: true },
      });
    }
  };
}

/* get suggestions */
export function handleSuggestion(value) {
  return async (dispatch) => {
    try {
      dispatch({
        type: ALBUMS_LOADING,
        payload: { doYouMeanLoading: true },
      });
      const response = await getSuggestions(value);
      dispatch({
        type: GET_SUGGESTION_RESPONSE,
        payload: response,
      });
      return response;
    } catch (error) {
      dispatch({
        type: ALBUMS_LOADING,
        payload: { doYouMeanLoading: false },
      });
    }
  };
}

/* get popular word */
export function handlePopularWord(value) {
  return async (dispatch) => {
    try {
      dispatch({
        type: ALBUMS_LOADING,
        payload: { popularWordLoading: true },
      });
      const response = await getPopularWord(value);
      dispatch({
        type: GET_POPULAR_WORD_RESPONSE,
        payload: response,
      });
      return response;
    } catch (error) {
      dispatch({
        type: ALBUMS_LOADING,
        payload: { popularWordLoading: false },
      });
    }
  };
}

/* Post add to cart data */
export function handleAddToCart(value) {
  return async (dispatch) => {
    try {
      dispatch({
        type: POST_ALBUMS_LOAD,
        payload: true,
      });
      const response = await postAddToCart(value);
      dispatch({
        type: POST_ADD_TO_CART,
        payload: response,
      });
      return response;
    } catch (error) {
      dispatch({
        type: POST_ALBUMS_ERROR,
        payload: { errorMessage: error.message },
      });
    }
  };
}

/* Post get to cart data */
export function handleCartDetail(location) {
  return async (dispatch) => {
    try {
      dispatch({
        type: POST_ALBUMS_LOAD,
        payload: true,
      });
      const response = await getCartDetail();
      dispatch({
        type: GET_CART_DETAIL,
        payload: response,
      });
      if (!response?.cart_details?.length) location.back();
    } catch (error) {
      dispatch({
        type: POST_ALBUMS_ERROR,
        payload: { errorMessage: error.message },
      });
    }
  };
}

/* delete to cart data */
export function handleRemoveCartDetail(value) {
  return async (dispatch) => {
    try {
      dispatch({
        type: POST_ALBUMS_LOAD,
        payload: true,
      });
      const response = await deleteCartDetail(value);
      dispatch({
        type: DELETE_CART_DETAIL,
        payload: response,
      });
    } catch (error) {
      dispatch({
        type: POST_ALBUMS_ERROR,
        payload: { errorMessage: error.message },
      });
    }
  };
}

/* delete to card data */

export function handleRemoveCard(value) {
  return async (dispatch) => {
    try {
      dispatch({
        type: POST_ALBUMS_LOAD,
        payload: true,
      });
      const response = await deleteCard(value);
      dispatch({
        type: DELETE_CARD_DETAIL,
        payload: response,
      });
    } catch (error) {
      dispatch({
        type: POST_ALBUMS_ERROR,
        payload: { errorMessage: error.message },
      });
    }
  };
}

export function handleGetCardDetails() {
  return async (dispatch) => {
    try {
      dispatch({
        type: POST_ALBUMS_LOAD,
        payload: true,
      });
      const response = await getUserPaymentMethod();
      dispatch({
        type: GET_ALL_CART_DETAIL,
        payload: response,
      });
    } catch (error) {
      dispatch({
        type: POST_ALBUMS_ERROR,
        payload: { errorMessage: error.message },
      });
    }
  };
}

/* post to cart checkout  */
export function handlePostUserPaymentMethod(value) {
  return async (dispatch) => {
    try {
      dispatch({
        type: POST_ALBUMS_LOAD,
        payload: true,
      });
      const response = await userPaymentMethod(value);
      return response;
    } catch (error) {
      dispatch({
        type: POST_ALBUMS_ERROR,
        payload: { errorMessage: error.message },
      });
    }
  };
}

/* post to cart checkout  */
export function handlePostPurchaseCart(value, Router) {
  return async (dispatch) => {
    try {
      dispatch({
        type: POST_ALBUMS_LOAD,
        payload: true,
      });
      const response = await postPurchaseCart(value);
      dispatch({
        type: POST_PURCHASE_CART,
        payload: response,
      });
      Router.push("/confirm-payment");
      return response;
    } catch (error) {
      dispatch({
        type: POST_ALBUMS_ERROR,
        payload: { errorMessage: error.message },
      });
    }
  };
}

export function handlePaymentFailed() {
  return async (dispatch) => {
    try {
      dispatch({
        type: POST_ALBUMS_LOAD,
        payload: true,
      });
      const response = await getPaymentFailed();
      dispatch({
        type: TOTAL_ATTEMPTS_CHECK,
        payload: response,
      });
      return response;
    } catch (error) {
      dispatch({
        type: POST_ALBUMS_ERROR,
        payload: { errorMessage: error.message },
      });
    }
  };
}

/* get to my library purchase track detail  */
export function handleMyLibraryPurchaseTracks() {
  return async (dispatch) => {
    try {
      dispatch({
        type: ALBUMS_LOADING,
        payload: { myLibraryPurchaseTrackLoading: true },
      });
      const response = await getMyLibraryPurchaseTrack();
      dispatch({
        type: GET_MY_LIBRARY_PURCHASE_TRACK,
        payload: response,
      });
    } catch (error) {
      dispatch({
        type: ALBUMS_LOADING,
        payload: { myLibraryPurchaseTrackLoading: false },
      });
    }
  };
}
/* get Library Upload Search*/
export function handleLibraryUploadSearch(value) {
  return async (dispatch) => {
    try {
      dispatch({
        type: ALBUMS_LOADING,
        payload: { libraryUploadSearchLoading: true },
      });
      const response = await getLibraryUploadSearch(value);
      dispatch({
        type: GET_LIBRARY_UPLOAD_SEARCH_RESPONSE,
        payload: response,
      });
    } catch (error) {
      dispatch({
        type: ALBUMS_LOADING,
        payload: { libraryUploadSearchLoading: false },
      });
    }
  };
}

/* get Library Purchase Search*/
export function handleLibraryPurchaseSearch(value) {
  return async (dispatch) => {
    try {
      dispatch({
        type: ALBUMS_LOADING,
        payload: { libraryPurchaseSearchLoading: true },
      });
      const response = await getLibraryPurchaseSearch(value);
      dispatch({
        type: GET_LIBRARY_PURCHASE_SEARCH_RESPONSE,
        payload: response,
      });
    } catch (error) {
      dispatch({
        type: ALBUMS_LOADING,
        payload: { libraryPurchaseSearchLoading: false },
      });
    }
  };
}

/* Post Promo Code*/
export function handlePostPromoCodes(value) {
  return async (dispatch) => {
    try {
      dispatch({
        type: POST_ALBUMS_LOAD,
        payload: true,
      });
      const response = await postPromoCode(value);
      dispatch({
        type: POST_PROMO_CODES,
        payload: response,
      });
    } catch (error) {
      if (error) {
        dispatch({
          type: POST_PROMO_CODES_ERROR,
          payload: error,
        });
      }
    }
  };
}

export function handlePaymentIntentService(value) {
  return async (dispatch) => {
    try {
      dispatch({
        type: POST_ALBUMS_LOAD,
        payload: true,
      });
      const response = await postPaymentIntentService(value);
      dispatch({
        type: GET_PAYMENT_INTENT,
        payload: response,
      });
      return response;
    } catch (error) {
      if (error) {
        dispatch({
          type: POST_ALBUMS_ERROR,
          payload: error,
        });
      }
    }
  };
}

export function handleSetUpPaymentIntentService(value) {
  return async (dispatch) => {
    try {
      dispatch({
        type: POST_ALBUMS_LOAD,
        payload: true,
      });
      const response = await postSetUpPaymentIntentService(value);
      return response;
    } catch (error) {
      if (error) {
        dispatch({
          type: POST_ALBUMS_ERROR,
          payload: error,
        });
      }
    }
  };
}

/* Get All Plans*/
export function handleGetAllPlans(_ROUTES) {
  return async (dispatch) => {
    try {
      dispatch({
        type: POST_ALBUMS_LOAD,
        payload: true,
      });
      const response = await getAllPlans();
      let route = getLocal("routers");
      if (route === "/auth/signUp") {
        response?.map((price) => {
          if (price?.name === "free") {
            price?.prices?.map((finalPrice) => {
              if (
                (finalPrice?.interval === "month" &&
                  finalPrice?.interval_count === "1") ||
                (finalPrice?.interval === "month" &&
                  finalPrice?.interval_count === "6") ||
                (finalPrice?.interval === "year" &&
                  finalPrice?.interval_count === "1")
              ) {
                finalPrice.current_active_plan = true;
              }
              return finalPrice;
            });
            return price;
          }
        });
      }

      const map = {
        free: 1,
        "plate mail games": 2,
        "battlebards prime": 3,
        "epic duo": 4,
      };

      response?.sort((a, b) => map[a.name] - map[b.name]);

      dispatch({
        type: GET_ALL_PLANS,
        payload: response,
      });
    } catch (error) {
      dispatch({
        type: POST_ALBUMS_ERROR,
        payload: { errorMessage: error?.message },
      });
    }
  };
}

/* Post postSubscription Code*/
export function handlePostSubscription(value) {
  return async (dispatch) => {
    try {
      dispatch({
        type: POST_ALBUMS_LOAD,
        payload: true,
      });
      const response = await postSubscription(value);
      dispatch({
        type: POST_SUBSCRIPTION,
        payload: response,
      });
    } catch (error) {
      dispatch({
        type: POST_ALBUMS_ERROR,
        payload: { errorMessage: error?.message },
      });
    }
  };
}

/* GET Subscription Code*/
export function handleGetSubscription() {
  return async (dispatch) => {
    try {
      dispatch({
        type: POST_ALBUMS_LOAD,
        payload: true,
      });
      const response = await getSubscription();
      dispatch({
        type: GET_SUBSCRIPTION,
        payload: response,
      });
    } catch (error) {
      dispatch({
        type: POST_ALBUMS_ERROR,
        payload: { errorMessage: error?.message },
      });
    }
  };
}

/* POST  Subscription change plan Code*/
export function handlePostSubscriptionChangePlan(value, Router) {
  return async (dispatch) => {
    try {
      dispatch({
        type: POST_ALBUMS_LOAD,
        payload: true,
      });
      const response = await postSubscriptionChangePlan(value);
      dispatch({
        type: POST_SUBSCRIPTION_CHANGE_PLAN,
        payload: response,
      });
      Router.push("/confirm-payment");
    } catch (error) {
      dispatch({
        type: POST_ALBUMS_ERROR,
        payload: { errorMessage: error?.message },
      });
    }
  };
}

/* POST  Subscription cancel plan Code*/
export function handlePostSubscriptionCancelPlan(value) {
  return async (dispatch) => {
    try {
      dispatch({
        type: POST_ALBUMS_LOAD,
        payload: true,
      });
      const response = await postSubscriptionCancelPlan(value);
      // dispatch({
      //   type: POST_SUBSCRIPTION_CANCEL_PLAN,
      //   payload: response,
      // });
      return response;
    } catch (error) {
      dispatch({
        type: POST_ALBUMS_ERROR,
        payload: { errorMessage: error?.message },
      });
    }
  };
}

/* POST  Subscription Draft Plan*/
export function handlePostSubscriptionDraftPlan(value) {
  return async (dispatch) => {
    try {
      dispatch({
        type: POST_ALBUMS_LOAD,
        payload: true,
      });
      const response = await postSubscriptionDraftPlan(value);
      dispatch({
        type: POST_SUBSCRIPTION_DRAFT_PLAN,
        payload: response,
      });
    } catch (error) {
      dispatch({
        type: POST_PROMO_CODES_ERROR,
        payload: error,
      });
    }
  };
}

/* Get Plan By Id*/
export function handleGetPlanById(id) {
  return async (dispatch) => {
    try {
      dispatch({
        type: POST_ALBUMS_LOAD,
        payload: true,
      });
      const response = await getPlanById(id);
      dispatch({
        type: GET_PLAN_BY_ID,
        payload: response ? response : {},
      });
    } catch (error) {
      dispatch({
        type: POST_ALBUMS_ERROR,
        payload: { errorMessage: error?.message },
      });
    }
  };
}

/* Get Plan By Id*/
export function handleGetPlanAvailableTrack(id) {
  return async (dispatch) => {
    try {
      dispatch({
        type: POST_ALBUMS_LOAD,
        payload: true,
      });
      const response = await getPlanAvailableTrack(id);
      dispatch({
        type: GET_PLAN_AVAILABLE_TRACK,
        payload: response,
      });
    } catch (error) {
      dispatch({
        type: POST_ALBUMS_ERROR,
        payload: { errorMessage: error?.message },
      });
    }
  };
}
//initial-track
export function handleHomePostInitialTrack(value, tokenSSR) {
  return async (dispatch) => {
    try {
      dispatch({
        type: POST_INITIAL_TRACK_LOAD,
        payload: true,
      });
      const response = await postInitialTrack(value, tokenSSR);

      /*  if (
        window.location.pathname === "/" &&
        value?.track_category === "popular"
      ) {
        let temp = response?.findIndex(
          (item) => item?.title === "Duntrimir and Ellassar"
        );
        let spiceArray = response.splice(temp, 1);
        let findObj = spiceArray?.find(
          (item) => item?.title === "Duntrimir and Ellassar"
        );
        response.unshift(findObj);
      } */
      dispatch({
        type: "GET_POPULAR_INITIAL_TRACK",
        payload: response,
      });
      return response;
    } catch (error) {
      dispatch({
        type: POST_INITIAL_TRACK_ERROR,
        payload: { errorMessage: error?.message },
      });
    }
  };
}

// //store-section
export function handleGetStoreSection(access_token, refresh_token) {
  return async (dispatch) => {
    try {
      dispatch({
        type: GET_DYNAMIC_STORE_LOADER,
        payload: true,
      });
      const response = await getStoreSection(access_token, refresh_token);
      dispatch({
        type: GET_STORE_SECTION,
        payload: response,
      });
      return response;
    } catch (error) {
      dispatch({
        type: GET_DYNAMIC_STORE_ERROR,
        payload: { errorMessage: error?.message },
      });
    }
  };
}

export function handleGetSectionItemDetails(id) {
  return async (dispatch) => {
    try {
      dispatch({
        type: GET_DYNAMIC_STORE_SECTION_ITEM_DETAIL_LOADER,
        payload: true,
      });
      const response = await getSectionItemDetails(id);
      dispatch({
        type: GET_STORE_SECTION_ITEM_DETAIL,
        payload: response,
      });
      return response;
    } catch (error) {
      dispatch({
        type: GET_DYNAMIC_STORE_SECTION_ITEM_DETAIL_LOADER,
        payload: false,
      });
      dispatch({
        type: GET_DYNAMIC_STORE_SECTION_ITEM_DETAIL_ERROR,
        payload: { errorMessage: error?.message },
      });
    }
  };
}

//initial-track
export function handlePostInitialTrackSec(value) {
  return async (dispatch) => {
    try {
      dispatch({
        type: POST_INITIAL_TRACK_LOAD,
        payload: true,
      });
      // setLoadingType(dispatch, value?.track_category, true);
      const response = await postPlanAvailableTrackSec(value);
      switch (value?.track_category) {
        case "newTrack":
          {
            dispatch({
              type: "GET_NEW_INITIAL_TRACK",
              payload: response,
            });
          }
          break;
        case "popular":
          {
            let res = [];
            if (
              typeof window !== undefined &&
              window.location.pathname === "/store/explore"
            ) {
              res = response?.filter(
                (item) => item?.title !== "Duntrimir and Ellassar"
              );
            }
            dispatch({
              type: "GET_POPULAR_INITIAL_TRACK",
              payload: res,
            });
          }
          break;
        case "mustHaves":
          {
            dispatch({
              type: "GET_MUST_HAVE_INITIAL_TRACK",
              payload: response,
            });
          }
          break;
        case "trendingByPlays":
          {
            dispatch({
              type: "GET_TRENDING_BY_PLAYS_INITIAL_TRACK",
              payload: response,
            });
          }
          break;
        case "trendingByPurchases":
          {
            dispatch({
              type: "GET_TRENDING_BY_PURCHASE_INITIAL_TRACK",
              payload: response,
            });
          }
          break;
      }
      // setLoadingType(dispatch, value?.track_category, false);
      return response;
    } catch (error) {
      setLoadingType(dispatch, value?.track_category, false);
      dispatch({
        type: POST_INITIAL_TRACK_ERROR,
        payload: { errorMessage: error?.message },
      });
    }
  };
}
const setAlbumsLoadingType = (dispatch, track_category, value) => {
  switch (track_category) {
    case "newAlbum":
      {
        dispatch({
          type: ALBUMS_LOADING,
          payload: { newInitialAlbumsLoading: value },
        });
      }
      break;
    case "popular":
      {
        dispatch({
          type: ALBUMS_LOADING,
          payload: { popularInitialAlbumsLoading: value },
        });
      }
      break;
    case "mustHaves":
      {
        dispatch({
          type: ALBUMS_LOADING,
          payload: { mustHaveInitialAlbumsLoading: value },
        });
      }
      break;
    case "trendingByPlays":
      {
        dispatch({
          type: ALBUMS_LOADING,
          payload: { trendingByPlaysInitialAlbumsLoading: value },
        });
      }
      break;
    case "trendingByPurchases":
      {
        dispatch({
          type: ALBUMS_LOADING,
          payload: { trendingByPurchaseInitialAlbumsLoading: value },
        });
      }
      break;
  }
};
//initial-Albums
export function handlePostInitialAlbumsSec(value) {
  return async (dispatch) => {
    try {
      // dispatch({
      //   type: POST_INITIAL_ALBUMS_LOAD,
      //   payload: true,
      // });
      // setAlbumsLoadingType(dispatch, value?.album_category, true)
      const response = await postPlanAvailableAlbumsSec(value);
      switch (value?.album_category) {
        case "newAlbum":
          {
            dispatch({
              type: "GET_NEW_INITIAL_ALBUMS",
              payload: response,
            });
          }
          break;
        case "popular":
          {
            dispatch({
              type: "GET_POPULAR_INITIAL_ALBUMS",
              payload: response,
            });
          }
          break;
        case "mustHaves":
          {
            dispatch({
              type: "GET_MUST_HAVE_INITIAL_ALBUMS",
              payload: response,
            });
          }
          break;
        case "trendingByPlays":
          {
            dispatch({
              type: "GET_TRENDING_BY_PLAYS_INITIAL_ALBUMS",
              payload: response,
            });
          }
          break;
        case "trendingByPurchases":
          {
            dispatch({
              type: "GET_TRENDING_BY_PURCHASE_INITIAL_ALBUMS",
              payload: response,
            });
          }
          break;
      }
      return response;
    } catch (error) {
      setAlbumsLoadingType(dispatch, value?.album_category, false);
      // dispatch({
      //   type: POST_INITIAL_ALBUMS_ERROR,
      //   payload: { errorMessage: error?.message },
      // });
    }
  };
}

//initial-track
export function handlePostInitialTrack(value, tokenSSR) {
  return async (dispatch) => {
    try {
      dispatch({
        type: POST_INITIAL_TRACK_LOAD,
        payload: true,
      });
      const response = await postInitialTrack(value, tokenSSR);
      dispatch({
        type: "GET_POPULAR_INITIAL_TRACK",
        payload: response,
      });
      return response;
    } catch (error) {
      dispatch({
        type: POST_INITIAL_TRACK_ERROR,
        payload: { errorMessage: error?.message },
      });
    }
  };
}

//initial-Albums
export function handlePostInitialAlbums(value, tokenSSR) {
  return async (dispatch) => {
    try {
      dispatch({
        type: POST_INITIAL_ALBUMS_LOAD,
        payload: true,
      });
      const response = await postInitialAlbums(value, tokenSSR);
      return response;
    } catch (error) {
      dispatch({
        type: POST_INITIAL_ALBUMS_ERROR,
        payload: { errorMessage: error?.message },
      });
    }
  };
}

//share track by uri name
export function handleGetTrackByUriName(value, tokenSSR, refreshSSR) {
  return async (dispatch) => {
    try {
      dispatch({
        type: POST_ALBUMS_LOAD,
        payload: true,
      });
      const response = await getShareTrackByUriName(value, tokenSSR, refreshSSR);
      return response;
    } catch (error) {
      dispatch({
        type: POST_ALBUMS_ERROR,
        payload: { errorMessage: error?.message },
      });
    }
  };
}
export const homePageCount = () => ({
  type: "HOME_PAGE_COUNT",
  payload: 0,
});

export function handleActiveInvitation(id) {
  return async (dispatch) => {
    try {
      dispatch({
        type: POST_ALBUMS_LOAD,
        payload: true,
      });
      const response = await postActiveInvitation(id);
      return response;
    } catch (error) {
      dispatch({
        type: POST_ALBUMS_ERROR,
        payload: { errorMessage: error?.message },
      });
    }
  };
}
export function handlePostSQS(value) {
  return async (dispatch) => {
    try {
      dispatch({
        type: POST_ALBUMS_LOAD,
        payload: true,
      });
      const response = await PostSQS(value);
      return response;
    } catch (error) {
      dispatch({
        type: POST_ALBUMS_ERROR,
        payload: { errorMessage: error?.message },
      });
    }
  };
}

export function handleCancelLegacyUserSubscription() {
  return async (dispatch) => {
    try {
      dispatch({
        type: POST_ALBUMS_LOAD,
        payload: true,
      });
      const response = await cancelLegacyUserSubscription();
      return response;
    } catch (error) {
      dispatch({
        type: POST_ALBUMS_ERROR,
        payload: { errorMessage: error?.message },
      });
    }
  };
}
