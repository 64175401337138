import { useCallback, useEffect, useState } from "react";
import { toast } from "react-toastify";
import Router from "next/router";
import AWS from "aws-sdk";
import { Sha256 } from "@aws-crypto/sha256-browser";
import disableScroll from "disable-scroll";
import "react-toastify/dist/ReactToastify.css";
import byteSize from "byte-size";

import { deleteCookie, getCookie, getLocal } from "./storage";

let parseDetails = getCookie("login_user") ? getCookie("login_user") : null;
const _TOKEN = getLocal("access_token");
export const useOnClickOutside = (ref, handler) => {
  useEffect(() => {
    const listener = (event) => {
      // Do nothing if clicking ref's element or descendent elements
      if (!ref.current || ref.current.contains(event.target)) {
        return;
      }
      handler(event);
    };
    document.addEventListener("mousedown", listener);
    document.addEventListener("touchstart", listener);
    return () => {
      document.removeEventListener("mousedown", listener);
      document.removeEventListener("touchstart", listener);
    };
  }, [ref, handler]);
};

export function useForceUpdate() {
  const [, setUpdate] = useState(0);
  const update = useCallback(() => {
    setUpdate((update) => update + 1);
  }, []);
  return update;
}

export const formatTime = (seconds) => {
  const h = Math.floor(seconds / 3600);
  const m = Math.floor((seconds % 3600) / 60);
  const s = seconds % 60;
  return [h, m > 9 ? m : h ? "0" + m : m || "0", s > 9 ? s : "0" + s]
    .filter((a) => a)
    .join(":");
};

export const region = "us-east-2";
export const getS3Credential = () => {
  const s3 = new AWS.S3({
    accessKeyId: "AKIAZ3QGYCFKKKTC24YZ",
    secretAccessKey: "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",
    region,
    sha256: Sha256,
  });
  return s3;
};

export const showToast = (status, msg, type) => {
  toast.success(
    <div className="p-l-15">
      <h1 className="fs-18 font-weight-normal">{status}</h1>
      <p className="fs-16">{msg}</p>
    </div>,
    {
      icon: () =>
        type === "success" ? (
          <i className="icon-check-alt fa-lg toast-success toster-icon" />
        ) : (
          <i className="icon-close-solid fa-lg text-danger toster-icon" />
        ),
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: true,
      closeButton: false,
    }
  );
};

export const generateArrayOfYears = () => {
  var max = new Date().getFullYear();
  var min = max - 5;
  var years = [];
  for (var i = max; i >= min; i--) {
    years.push({ label: i, value: i });
  }
  return years;
};

export const colourStyles = {
  placeholder: (base) => ({
    ...base,
    // backgroundColor: "black",
    fontSize: "15px",
    fontFamily: "Poppins",
    color: "#ffffff",
  }),
  clearIndicator: (prevStyle) => ({
    ...prevStyle,
    color: "#717171",
    ":hover": {
      color: "#dcba6c",
    },
  }),
  menuList: (base) => ({
    ...base,
    maxHeight: "200px", // your desired height
  }),
  control: (provided, state) => ({
    ...provided,
    boxShadow: "none",
    border: state.isFocused && "#fff",
    backgroundColor: "#333",
    minHeight: "40px",
    cursor: "pointer",
  }),
  dropdownIndicator: (base) => ({
    ...base,
    fontSize: "14px",
    "&:hover": {
      color: "#dcba6c",
    },
  }),
  singleValue: (base) => ({
    ...base,
    color: "#fff",
    fontSize: "15px",
    fontFamily: "Poppins",
    padding: "15px 5px 14px 20px",
  }),
  input: (base) => ({
    ...base,
    color: "#fff",
    fontSize: "15px",
    fontFamily: "Poppins",
  }),
  menu: (provided, state) => ({
    ...provided,
    border: "none",
    boxShadow: "none",
    backgroundColor: "#333",
    textColor: "#fff",
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor:
      state?.data?.__isNew__ === true
        ? "#dcba6c"
        : state.isFocused
        ? "#dcba6c"
        : "#333",
    color: state.isFocused && "#222222",
    ":active": {
      backgroundColor: "#dcba6c"
        ? state.isFocused
          ? "#dcba6c"
          : "#dcba6c"
        : undefined,
    },
    cursor: "pointer",
    overflow: "auto",
    overflowX: "hidden",
    overflowY: "auto",
    width: "100%",
    zIndex: "9999",
  }),
};

export const albumsColorStyle = {
  placeholder: (base) => ({
    ...base,
    // backgroundColor: "black",
    fontSize: "15px",
    fontFamily: "Poppins",
    color: "#ffffff",
    padding: "10px 5px 10px 15px",
  }),
  clearIndicator: (prevStyle) => ({
    ...prevStyle,
    color: "#717171",
    ":hover": {
      color: "#dcba6c",
    },
  }),
  menuList: (base) => ({
    ...base,
    maxHeight: "200px", // your desired height
  }),
  control: (provided, state) => ({
    ...provided,
    boxShadow: "none",
    border: state.isFocused && "#222222",
    backgroundColor: "#222222",
    border: "#222222",
    minHeight: "45px",
    cursor: "pointer",
    borderRadius: "30px",
  }),
  dropdownIndicator: (base) => ({
    ...base,
    fontSize: "14px",
    "&:hover": {
      color: "#dcba6c",
    },
  }),
  singleValue: (base) => ({
    ...base,
    color: "#fff",
    fontSize: "15px",
    fontFamily: "Poppins",
    padding: "10px 5px 10px 15px",
  }),
  input: (base) => ({
    ...base,
    color: "#fff",
    fontSize: "14px",
    fontFamily: "Poppins",
  }),
  menu: (provided, state) => ({
    ...provided,
    border: "none",
    boxShadow: "none",
    backgroundColor: "#222222",
    textColor: "#fff",
  }),
  option: (provided, state, styles) => ({
    ...provided,
    backgroundColor:
      state?.data?.__isNew__ === true
        ? "#dcba6c"
        : state.isFocused
        ? "#dcba6c"
        : "#333",
    color: state.isFocused && "#222222",
    ":active": {
      backgroundColor: "#dcba6c"
        ? state.isFocused
          ? "#dcba6c"
          : "#dcba6c"
        : undefined,
    },
    cursor: "pointer",
    overflow: "auto",
    overflowX: "hidden",
    overflowY: "auto",
    width: "100%",
    zIndex: "9999",
  }),
};
// react select get key-value for default value
export const getKeyName = (arrList, id) => {
  if (arrList && arrList.length) return arrList.find((_) => _.id === id);
};

// react-select getLabelValue for option
export const getLabelValue = (arrName) => {
  const finalArray = [];
  arrName?.forEach((e) =>
    finalArray?.push({ label: e.audio_type, value: e.audio_type_id })
  );
  return finalArray;
};

export const getKeyLabel = (arrList, id) => {
  const finalArray = [];
  if (arrList && arrList.length)
    arrList?.forEach((e) => {
      if (e?.id === id) finalArray?.push({ label: e.section, value: e.id });
    });
  return finalArray;
};

export const getLabelValueTrack = (arrName) => {
  const finalArray = [];
  arrName?.forEach((e) => finalArray?.push({ label: e.name, value: e.id }));
  return finalArray;
};

export const bytesToSize = (bytes) => {
  var sizes = ["Bytes", "KB", "MB", "GB", "TB"];
  if (bytes === 0) return "0 Byte";
  var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
  return parseFloat(bytes / Math.pow(1024, i)).toFixed(2) + " " + sizes[i];
};

export const uniqueId = () => {
  var dt = new Date().getTime();
  var uuid = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
    /[xy]/g,
    function (c) {
      var r = (dt + Math.random() * 16) % 16 | 0;
      dt = Math.floor(dt / 16);
      return (c === "x" ? r : (r & 0x3) | 0x8).toString(16);
    }
  );
  return uuid;
};

export const searchInArray = (str, array, key) =>
  array.filter((data) => {
    const pattern = new RegExp(str.toLowerCase());
    if (key) return data[key].toLowerCase().match(pattern);
    else return data.toLowerCase().match(pattern);
  });

export const compareDates = (date1, date2) => {
  if (date2 > date1) return true;
  else if (date1 < date2) return false;
  else return false;
};

export const redirectFn = (res) => {
  deleteAllCookies();
};

export const deleteAllCookies = () => {
  if (typeof document !== "undefined") {
    var cookies = document.cookie.split(";");

    for (var i = 0; i < cookies.length; i++) {
      var cookie = cookies[i];
      var eqPos = cookie.indexOf("=");
      var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
      document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
    }
  }
};

export const titleCase = (str) => {
  var splitStr = str?.toLowerCase().split(" ");
  for (var i = 0; i < splitStr?.length; i++) {
    // You do not need to check if i is larger than splitStr length, as your for does that for you
    // Assign it back to the array
    splitStr[i] =
      splitStr[i]?.charAt(0)?.toUpperCase() + splitStr[i]?.substring(1);
  }
  // Directly return the joined string
  return splitStr?.join(" ");
};

export const negativeCheck = (number) => number <= 0;

export const byteSizeValue = (bytes) => {
  if (isNaN(bytes)) {
    return "0";
  } else {
    return byteSize(bytes, { units: "iec" })?.value;
  }
};

export const byteSizeUnit = (bytes) =>
  byteSize(bytes, { units: "iec" })?.unit.replace("i", "");

export const updateArrayKeyValue = (array, id, key) => {
  for (var i in array) {
    if (array[i].uuid == id) {
      array[i].error = key;
      break; //Stop this loop, we found it!
    }
  }
};

export const checkDeviceComparability = () => {
  if (
    typeof navigator !== "undefined" &&
    navigator?.userAgent &&
    /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i?.test(
      navigator?.userAgent
    )
  ) {
    return true;
  } else {
    return false;
  }
};

export const checkLogOrNot = (_TOKEN, pathRecord) => {
  if (pathRecord === "/store/explore" && !_TOKEN) {
    return;
  } else if (pathRecord !== "/store/explore" && _TOKEN) {
    return Router.push("/store/explore");
  } else if (pathRecord === "/store/explore" && _TOKEN) {
    return null;
  } else {
    return Router.push("/");
  }
};

export const disableScrolled = (props) => disableScroll[props ? "on" : "off"]();

export const enableScrolled = () => disableScroll.on();
export const offScrolled = () => disableScroll.off();

export const onlineOfflineStatus = () => {
  window.addEventListener("offline", function (e) {
    showToast("Failed !", "Lost connection!", "error");
  });

  window.addEventListener("online", function (e) {
    showToast("Successfully !", "Back online!", "success");
  });
};

export const calculateSum = (array, property) => {
  let total = 0;
  array?.forEach((ele) => {
    total += parseInt(ele.count_data);
  });
  return total;
};

export const unixTimeStamp = () => Math.floor(new Date().getTime() / 1000);
export const formatDate = (date) => {
  if (date !== null) {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  }
};

export const deleteFinalCookie = () => {
  deleteCookie("login_user");
  deleteCookie("cast_channel");
};

export const getMobileOperatingSystem = () => {
  var userAgent = navigator.userAgent || navigator.vendor || window.opera;

  // Windows Phone must come first because its UA also contains "Android"
  if (/windows phone/i.test(userAgent)) {
    return "Windows Phone";
  }

  if (/android/i.test(userAgent)) {
    return "Android";
  }

  // iOS detection from: http://stackoverflow.com/a/9039885/177710
  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return "iOS";
  }

  return "unknown";
};

export const copyTextToClipboard = async (text) => {
  if ("clipboard" in navigator) {
    await navigator.clipboard.writeText(text);
  } else {
    document.execCommand("copy", true, text);
  }
};

export function getNextFifthDate() {
  const currentDate = new Date();
  const currentDay = currentDate.getDate();
  const targetMonth =
    currentDay <= 5 ? currentDate.getMonth() : currentDate.getMonth() + 1;
  const targetYear =
    targetMonth === 0
      ? currentDate.getFullYear() + 1
      : currentDate.getFullYear();

  return new Date(targetYear, targetMonth, 5);
}

export function formatDateWithOrdinal(date) {
  const day = date.getDate();
  const options = { year: "numeric", month: "long", day: "numeric" };
  return date
    .toLocaleDateString("en-US", options)
    .replace(
      day,
      day + (day === 1 ? "st" : day === 2 ? "nd" : day === 3 ? "rd" : "th")
    );
}

export function mergeObjectsWithUniqueIds(...objectsList) {
  const mergedObjects = {};

  for (const obj of objectsList) {
    if (!mergedObjects[obj.section_id]) {
      mergedObjects[obj.section_id] = { ...obj };
    } else {
      mergedObjects[obj.section_id] = {
        ...mergedObjects[obj.section_id],
        ...obj,
      };
    }
  }
  return Object.values(mergedObjects);
}
