import React, { useState } from "react";
import Image from "next/image";
const index = ({
  src,
  alt,
  className,
  loading,
  width,
  height,
  onError,
  sampleImage,
  layout,
  redirect
}) => {
  const [errorImage, setErrorImage] = useState(null);
  const imgUrl = errorImage ? sampleImage : src;
  return (
    <Image
      src={imgUrl}
      alt={alt}
      className={className}
      loading={loading}
      priority
      width={width}
      height={height}
      layout={layout}
      onError={(e) => {
        if (!errorImage) {
          setErrorImage(true);
        }
      }}
      onClick={()=> redirect()}
    />
  );
};

export default index;
