import * as Actions from "../../actions/dashboard/index";
const initialState = {
  salesData: [],

  performanceCatalog: {
    totalRows: 0,
    data: [],
  },
  performanceAnalytics: {
    totalRows: 0,
    data: [],
  },
  activitySalesLog: {
    totalRows: 0,
    data: [],
  },
  activitySubscriptionLog: {
    totalRows: 0,
    data: [],
  },

  partnersList: [],
  partnerEarningLifeTimeCurrent: [],
};

const Dashboard = function (state = initialState, action) {
  switch (action.type) {
    case Actions.SET_PERFORMANCE_CATALOG: {
      return {
        ...state,
        performanceCatalog: action?.payload,
      };
    }
    case Actions.RESET_PERFORMANCE_CATALOG: {
      return {
        ...state,
        performanceCatalog: initialState.performanceCatalog,
      };
    }

    case Actions.SET_PERFORMANCE_ANALYTICS: {
      return {
        ...state,
        performanceAnalytics: action?.payload,
      };
    }
    case Actions.RESET_PERFORMANCE_ANALYTICS: {
      return {
        ...state,
        performanceAnalytics: initialState.performanceAnalytics,
      };
    }

    case Actions.SET_ACTIVITY_SALES_LOG: {
      return {
        ...state,
        activitySalesLog: action?.payload,
      };
    }
    case Actions.RESET_ACTIVITY_SALES_LOG: {
      return {
        ...state,
        activitySalesLog: initialState.activitySalesLog,
      };
    }

    case Actions.SET_SALES_DATA: {
      return {
        ...state,
        salesData: action?.payload,
      };
    }
    case Actions.RESET_SALES_DATA: {
      return {
        ...state,
        salesData: initialState.salesData,
      };
    }

    case Actions.SET_SUBSCRIPTION_LOG: {
      return {
        ...state,
        activitySubscriptionLog: action?.payload,
      };
    }
    case Actions.RESET_SUBSCRIPTION_LOG: {
      return {
        ...state,
        activitySubscriptionLog: initialState.activitySubscriptionLog,
      };
    }
    case Actions.SET_ALL_PARTNERS: {
      return {
        ...state,
        partnersList: action?.payload || [],
      };
    }

    default: {
      return state;
    }
  }
};
export default Dashboard;
