import * as Actions from "../../actions/auth/index";
import { setLocal } from "../../../common/function/storage";
const initialState = {
  loading: false,
  first_time_login: false,
};

const Auth = function (state = initialState, action) {
  switch (action.type) {
    case Actions.POST_AUTH_LOAD: {
      return {
        ...state,
        loading: true,
      };
    }
    case Actions.POST_LOGIN: {
      const userDetails = {
        fullname: action?.payload?.fullname,
        email: action?.payload?.email,
        already_logged_in: action?.payload?.already_logged_in,
        bb_user_id: action?.payload?.bb_user_id,
        connected_to_bb: action?.payload?.connected_to_bb,
        first_time_login: false,
        public_id: action?.payload?.public_id,
        reset_password: action?.payload?.reset_password,
        partner_id: action?.payload?.partner_id,
        artist_id: action?.payload?.artist_id,
        rolename: action?.payload?.rolename,
      };
      setLocal("login_user", JSON.stringify(userDetails));
      setLocal("access_token", action?.payload?.access_token);
      setLocal("refresh_token", action?.payload?.refresh_token);
      return {
        ...state,
        loading: false,
      };
    }

    case Actions.GET_AUTH_VERITY: {
      const userDetails = {
        fullname: action?.payload?.fullname,
        email: action?.payload?.email,
        already_logged_in: action?.payload?.already_logged_in,
        bb_user_id: action?.payload?.bb_user_id,
        connected_to_bb: action?.payload?.connected_to_bb,
        first_time_login: true,
        public_id: action?.payload?.public_id,
        reset_password: action?.payload?.reset_password,
        partner_id: action?.payload?.partner_id,
        artist_id: action?.payload?.artist_id,
        rolename: action?.payload?.rolename,
      };
      setLocal("login_user", JSON.stringify(userDetails));
      setLocal("access_token", action?.payload?.access_token);
      setLocal("refresh_token", action?.payload?.refresh_token);
      return {
        ...state,
        loading: false,
      };
    }

    case Actions.GET_AUTH_VERITY:
    case Actions.POST_SIGNUP:
    case Actions.POST_RESET_PASSWORD:
    case Actions.GET_FORGOT_PASSWORD:
    case Actions.GET_SEND_MAIL: {
      return {
        ...state,
        loading: false,
      };
    }

    case Actions.GET_LOGOUT: {
      return {
        ...state,
        loading: false,
      };
    }

    case Actions.POST_AUTH_ERROR: {
      return {
        ...state,
        loading: false,
      };
    }

    case Actions.GET_FORGOT_PASSWORD: {
      return {
        ...state,
        loading: false,
        error: false,
        message: "",
      };
    }

    default: {
      return state;
    }
  }
};
export default Auth;
