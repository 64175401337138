export const getCurWaveTime = (wave) => {
  try {
    if (wave && wave?.getCurrentTime) {
      return wave?.getCurrentTime();
    }
    return 0;
  } catch (error) {
    return 0;
  }
};
