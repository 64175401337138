import * as Sentry from "@sentry/react";
import log from "loglevel";

export const errorLog = (message) => {
  if (message?.response?.status >= 400 && message?.response?.status <= 500) {
    return message;
  } else {
    Sentry.captureException(message);
  }
  log.error({ message });
};

export const debugLog = (message) => {
  log.error({ message });
};

export const userLoggedInLog = (message) => {
  log.warn({ message });
};
