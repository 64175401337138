import { createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import {createWrapper } from 'next-redux-wrapper';

// import { createLogger } from "redux-logger";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web
import rootReducer from "./reducers/root-reducer";

const composeEnhancers =
  typeof window === "object" && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
        // Specify extension’s options like name, actionsBlacklist, actionsCreators, serialize...
      })
    : compose;

const enhancer = composeEnhancers(
  applyMiddleware(thunk)
  // other store enhancers if any
);
// const store = createStore(rootReducer, enhancer);
const store = createStore(()=>[rootReducer, enhancer], {}, applyMiddleware());

const persistConfig = {
  key: "root",
  storage,
  store,
  whitelist: ["memorize"],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const stores = () => {
  let store = createStore(persistedReducer, enhancer);
  let persistor = persistStore(store);
  return { store, persistor };
};


const initStore = () => createStore(
  persistedReducer,
  enhancer,
);

export const wrapper = createWrapper(initStore, { debug: false });

export default stores;
