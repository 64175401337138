import {
  getArtistActivitySalesLogs,
  getArtistDashBoardData,
  getArtistPerformanceCatalogData,
  getPartnerActivitySalesLogs,
  getPartnerActivitySubscriptionLogs,
  getPartnerDashBoardData,
  getPartnerPerformanceCatalogData,
  getPerformanceAnalyticsLogs,
} from "../../../services/auth";
import { getAllPartners } from "../../../services/dashboard";

export const SET_PERFORMANCE_ANALYTICS =
  "[DASHBOARD] SET_PERFORMANCE_ANALYTICS";
export const RESET_PERFORMANCE_ANALYTICS =
  "[DASHBOARD]  RESET_PERFORMANCE_ANALYTICS";

export const SET_PERFORMANCE_CATALOG = "[DASHBOARD] SET_PERFORMANCE_CATALOG";
export const RESET_PERFORMANCE_CATALOG =
  "[DASHBOARD]  RESET_PERFORMANCE_CATALOG";

export const SET_ACTIVITY_SALES_LOG = "[DASHBOARD] SET_ACTIVITY_SALES_LOG";
export const RESET_ACTIVITY_SALES_LOG = "[DASHBOARD] ACTIVITY_SALES_LOG";

export const SET_SALES_DATA = "[DASHBOARD] SET_SALES_DATA";
export const RESET_SALES_DATA = "[DASHBOARD] SALES_DATA";

export const SET_SUBSCRIPTION_LOG = "[DASHBOARD] SET_SUBSCRIPTION_LOG";
export const RESET_SUBSCRIPTION_LOG = "[DASHBOARD] SUBSCRIPTION_LOG";

export const SET_ALL_PARTNERS = "[DASHBOARD] SET_ALL_PARTNERS";

export function handlePartnerPerformanceCatalog(params) {
  return async (dispatch) => {
    try {
      const response = await getPartnerPerformanceCatalogData(params);
      const resData = response?.data?.data;
      const temp = {
        totalRows: resData?.track_sale_count || 0,
        data: resData?.track_sale_data || [],
      };
      dispatch({
        type: SET_PERFORMANCE_CATALOG,
        payload: temp,
      });
      return response;
    } catch (error) {
      dispatch({
        type: RESET_PERFORMANCE_CATALOG,
        payload: null,
      });
    }
  };
}

export function handlePartnerActivitySalesLog(params) {
  return async (dispatch) => {
    try {
      const response = await getPartnerActivitySalesLogs(params);
      const resData = response?.data?.data;
      const temp = {
        totalRows: resData?.track_sale_count || 0,
        data: resData?.track_sale_data || [],
        total_track_sale_amount : resData?.total_track_sale_amount
      };
      dispatch({
        type: SET_ACTIVITY_SALES_LOG,
        payload: temp,
      });
      return response;
    } catch (error) {
      dispatch({
        type: RESET_ACTIVITY_SALES_LOG,
        payload: null,
      });
    }
  };
}

export function handleSalesDataLog(params) {
  return async (dispatch) => {
    try {
      const response = await getPartnerDashBoardData(params);
      const resData = response?.data?.data;
      dispatch({
        type: SET_SALES_DATA,
        payload: resData,
      });
      return response;
    } catch (error) {
      dispatch({
        type: RESET_SALES_DATA,
        payload: null,
      });
    }
  };
}

export function handleSubscriptionLog(params) {
  return async (dispatch) => {
    try {
      const response = await getPartnerActivitySubscriptionLogs(params);
      const resData = response?.data?.data;
      const temp = {
        totalRows: resData?.total_subscription_count || 0,
        data: resData?.sub_sale_data || [],
        total_subscription_count: resData?.total_subscription_count || 0,
        total_subscription_amount: resData?.total_subscription_amount || 0,
        total_subscribers: resData?.total_subscribers || 0
      };
      dispatch({
        type: SET_SUBSCRIPTION_LOG,
        payload: temp,
      });
      return response;
    } catch (error) {
      dispatch({
        type: RESET_SUBSCRIPTION_LOG,
        payload: null,
      });
    }
  };
}

export function handlePerformanceAnalyticsLog(params) {
  return async (dispatch) => {
    try {
      const response = await getPerformanceAnalyticsLogs(params);
      const resData = response?.data?.data;

      const temp = {
        totalRows: resData?.total_count || 0,
        data: resData?.retData || [],
      };
      dispatch({
        type: SET_PERFORMANCE_ANALYTICS,
        payload: temp,
      });
      return response;
    } catch (error) {
      dispatch({
        type: RESET_PERFORMANCE_ANALYTICS,
        payload: null,
      });
    }
  };
}

// artist
export function handleArtistSalesDataLog(params) {
  return async (dispatch) => {
    try {
      const response = await getArtistDashBoardData(params);
      const resData = response?.data?.data;
      dispatch({
        type: SET_SALES_DATA,
        payload: resData,
      });
      return response;
    } catch (error) {
      dispatch({
        type: RESET_SALES_DATA,
        payload: null,
      });
    }
  };
}

export function handleArtistPerformanceCatalog(params) {
  return async (dispatch) => {
    try {
      const response = await getArtistPerformanceCatalogData(params);
      const resData = response?.data?.data;
      const temp = {
        totalRows: resData?.track_sale_count || 0,
        data: resData?.track_sale_data || [],
      };
      dispatch({
        type: SET_PERFORMANCE_CATALOG,
        payload: temp,
      });
      return response;
    } catch (error) {
      dispatch({
        type: RESET_PERFORMANCE_CATALOG,
        payload: null,
      });
    }
  };
}

export function handleArtistActivitySalesLog(params) {
  return async (dispatch) => {
    try {
      const response = await getArtistActivitySalesLogs(params);
      const resData = response?.data?.data;
     
      const temp = {
        totalRows: resData?.total_data_count || 0,
        data: resData?.track_sale_data || [],
      };
      dispatch({
        type: SET_ACTIVITY_SALES_LOG,
        payload: temp,
      });
      return response;
    } catch (error) {
      dispatch({
        type: RESET_ACTIVITY_SALES_LOG,
        payload: null,
      });
    }
  };
}

export function handleGetAllPartner() {
  return async (dispatch) => {
    try {
      const response = await getAllPartners();
      let resData = response?.data?.data || [];
      resData = resData.map((item) => {
        return { ...item, label: item.email, value: item.partner_id };
      });

      dispatch({
        type: SET_ALL_PARTNERS,
        payload: resData,
      });
      return response;
    } catch (error) {
      dispatch({
        type: SET_ALL_PARTNERS,
        payload: [],
      });
    }
  };
}
