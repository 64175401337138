import { mergeObjectsWithUniqueIds } from "../../../common/function/function";
import * as Actions from "../../actions/albums/index";
const initialState = {
  loading: false,
  getAlbums: [],
  albumsByIdLoading: true,
  getAlbumsById: [],
  audioTypeLoading: true,
  audioTypes: [],
  getTrendingTrack: [],
  getFeaturedTrack: [],
  getTrendingAlbum: [],
  getFeaturedAlbum: [],
  tracksLibraryUploadAlbumsLoading: false,
  getTracksLibraryUploadAlbums: [],
  openFilterSearchLoading: [],
  getOpenFilterSearchData: [],
  getSuggestionData: [],
  popularWordLoading: false,
  getPopularWordData: [],
  getCartDetail: [],
  getAllCartDetail: [],
  myLibraryPurchaseTrackLoading: true,
  getMylibraryPurchaseTrack: [],
  libraryUploadSearchLoading: true,
  libraryUploadSearchData: [],
  libraryPurchaseSearchLoading: true,
  libraryPurchaseSearchData: [],
  postPromoCodeError: "",
  postPromoCodeResponse: {},
  getAllPlans: [],
  getSubscription: [],
  getSubscriptionDraftPlan: {},
  getPlanById: {},
  getPlanAvailableTrack: [],
  //track-management
  getInitialTrackLoading: true,
  getNewInitialTrack: [],
  popularTrackLoading: true,
  getPopularInitialTrack: [],
  mustHaveTrackLoading: true,
  getMustHaveInitialTrack: [],
  trendingByPlaysTrackLoading: true,
  getTrendingByPlaysInitialTrack: [],
  trendingByPurchaseTrackLoading: true,
  getTrendingByPurchaseInitialTrack: [],

  //Albums-management
  newInitialAlbumsLoading: true,
  getNewInitialAlbums: [],

  popularInitialAlbumsLoading: true,
  getPopularInitialAlbums: [],

  mustHaveInitialAlbumsLoading: true,
  getMustHaveInitialAlbums: [],

  trendingByPlaysInitialAlbumsLoading: true,
  getTrendingByPlaysInitialAlbums: [],

  trendingByPurchaseInitialAlbumsLoading: true,
  getTrendingByPurchaseInitialAlbums: [],

  //get Share track detail
  getShareIndividualTrack: {},
  failedPayment: null,
  getAlbumsLoading: false,
  getAlbumsError: true,

  getInitialTrackError: false,

  // getInitialAlbumsLoading: false,
  getInitialAlbumsError: false,
  getStoreSection: [],
  getStoreSectionLoader: false,
  getStoreSectionItemDetail: [],
  getStoreSectionItemDetailLoader: true,
};

const albums = function (state = initialState, action) {
  switch (action.type) {
    case Actions.ALBUMS_LOADING: {
      return {
        ...state,
        ...action?.payload,
      };
    }

    case Actions.POST_ALBUMS_LOAD: {
      return {
        ...state,
        loading: true,
      };
    }

    case Actions.POST_AUDIO_TYPES_LOAD: {
      return {
        ...state,
        // audioTypeLoading: true,
      };
    }

    case Actions.POST_AUDIO_TYPES_ERROR: {
      return {
        ...state,
        audioTypeLoading: false,
      };
    }

    //POST_INITIAL_TRACK
    case Actions.POST_INITIAL_TRACK_LOAD: {
      return {
        ...state,
        loading: true,
        // getInitialTrackLoading: true,
        getInitialTrackError: false,
      };
    }
    case Actions.POST_INITIAL_TRACK_ERROR: {
      return {
        ...state,
        loading: true,
        getInitialTrackLoading: false,
        getInitialTrackError: true,
      };
    }

    //POST_INITIAL_ALBUMS
    case Actions.POST_INITIAL_ALBUMS_LOAD: {
      return {
        ...state,
        loading: true,
        // getInitialAlbumsLoading: true,
        getInitialAlbumsError: false,
      };
    }
    case Actions.POST_INITIAL_ALBUMS_ERROR: {
      return {
        ...state,
        loading: true,
        // getInitialAlbumsLoading: false,
        getInitialAlbumsError: true,
      };
    }

    case Actions.POST_ALBUMS_FILTER_LOAD: {
      return {
        ...state,
        loading: true,
        getAlbumsLoading: true,
        getAlbumsError: false,
      };
    }
    case Actions.POST_ALBUMS_FILTER_ERROR: {
      return {
        ...state,
        loading: true,
        getAlbumsLoading: false,
        getAlbumsError: true,
        getAlbums: [],
      };
    }
    case "GET_ALBUMS": {
      return {
        ...state,
        getAlbums: action?.payload,
        loading: true,
        getAlbumsLoading: false,
        getAlbumsError: false,
      };
    }
    case "GET_ALBUMS_BY_ID": {
      return {
        ...state,
        getAlbumsById: action?.payload,
        albumsByIdLoading: false,
      };
    }
    case Actions.POST_ALBUMS_ERROR: {
      return {
        ...state,
        audioTypeLoading: false,
      };
    }
    case "GET_AUDIO_TYPES": {
      return {
        ...state,
        audioTypes: action?.payload,
        audioTypeLoading: false,
      };
    }
    case "GET_TRENDING_TRACK": {
      return {
        ...state,
        getTrendingTrack: action?.payload,
        loading: false,
      };
    }
    case "GET_FEATURED_TRACK": {
      return {
        ...state,
        getFeaturedTrack: action?.payload,
        loading: false,
      };
    }

    case "GET_TRENDING_ALBUM": {
      return {
        ...state,
        getTrendingAlbum: action?.payload,
        loading: false,
      };
    }
    case "GET_FEATURED_ALBUM": {
      return {
        ...state,
        getFeaturedAlbum: action?.payload,
        loading: false,
      };
    } //

    case "GET_TRACKS_LIBRARY_UPLOAD_ALBUM": {
      return {
        ...state,
        getTracksLibraryUploadAlbums: action?.payload,
        tracksLibraryUploadAlbumsLoading: false,
      };
    }

    case Actions.GET_OPEN_FILTER_RESPONSE: {
      return {
        ...state,
        getOpenFilterSearchData: action?.payload,
        openFilterSearchLoading: false,
      };
    }
    case Actions.GET_SUGGESTION_RESPONSE: {
      const result = [
        ...new Set(
          [].concat(...action?.payload?.stringSuggetion.map((o) => o.options))
        ),
      ];
      return {
        ...state,
        getSuggestionData: action?.payload,
        getDoYouMean: result,
        doYouMeanLoading: false,
      };
    }
    case Actions.GET_POPULAR_WORD_RESPONSE: {
      return {
        ...state,
        getPopularWordData: action?.payload,
        popularWordLoading: false,
      };
    }
    case Actions.GET_CART_DETAIL: {
      return {
        ...state,
        getCartDetail: action?.payload,
        loading: false,
      };
    }
    case Actions.GET_ALL_CART_DETAIL: {
      return {
        ...state,
        getAllCartDetail: action?.payload,
        loading: false,
      };
    }
    case Actions.GET_MY_LIBRARY_PURCHASE_TRACK: {
      return {
        ...state,
        getMylibraryPurchaseTrack: action?.payload,
        myLibraryPurchaseTrackLoading: false,
      };
    }
    case Actions.GET_LIBRARY_UPLOAD_SEARCH_RESPONSE: {
      return {
        ...state,
        libraryUploadSearchData: action?.payload,
        libraryUploadSearchLoading: false,
      };
    }
    case Actions.GET_LIBRARY_PURCHASE_SEARCH_RESPONSE: {
      return {
        ...state,
        libraryPurchaseSearchData: action?.payload,
        libraryPurchaseSearchLoading: false,
      };
    }
    case Actions.POST_PROMO_CODES: {
      return {
        ...state,
        postPromoCodeResponse: action?.payload,
      };
    }
    case Actions.POST_PROMO_CODES_ERROR: {
      return {
        ...state,
        postPromoCodeError: action?.payload,
      };
    }
    case Actions.GET_ALL_PLANS: {
      return {
        ...state,
        getAllPlans: action?.payload,
      };
    }
    case Actions.GET_SUBSCRIPTION: {
      return {
        ...state,
        getSubscription: action?.payload,
      };
    }
    case Actions.POST_SUBSCRIPTION_DRAFT_PLAN: {
      return {
        ...state,
        getSubscriptionDraftPlan: action?.payload,
      };
    }
    case Actions.GET_PLAN_BY_ID: {
      return {
        ...state,
        getPlanById: action?.payload,
      };
    }
    case Actions.GET_PLAN_AVAILABLE_TRACK: {
      return {
        ...state,
        getPlanAvailableTrack: action?.payload,
      };
    }
    //track-management
    //GET_NEW_INITIAL_TRACK
    case "GET_NEW_INITIAL_TRACK": {
      return {
        ...state,
        getNewInitialTrack: action?.payload,
        // loading: false,
        getInitialTrackLoading: false,
        getInitialTrackError: false,
      };
    }
    //GET_POPULAR_INITIAL_TRACK
    case "GET_POPULAR_INITIAL_TRACK": {
      return {
        ...state,
        getPopularInitialTrack: action?.payload,
        // loading: false,
        popularTrackLoading: false,
        getInitialTrackError: false,
      };
    }
    //GET_MUST_HAVE_INITIAL_TRACK
    case "GET_MUST_HAVE_INITIAL_TRACK": {
      return {
        ...state,
        getMustHaveInitialTrack: action?.payload,
        // loading: false,
        mustHaveTrackLoading: false,
        getInitialTrackError: false,
      };
    }
    //GET_TRENDING_BY_PLAYS_INITIAL_TRACK
    case "GET_TRENDING_BY_PLAYS_INITIAL_TRACK": {
      return {
        ...state,
        getTrendingByPlaysInitialTrack: action?.payload,
        // loading: false,
        trendingByPlaysTrackLoading: false,
        getInitialTrackError: false,
      };
    }
    //GET_TRENDING_BY_PURCHASE_INITIAL_TRACK
    case "GET_TRENDING_BY_PURCHASE_INITIAL_TRACK": {
      return {
        ...state,
        getTrendingByPurchaseInitialTrack: action?.payload,
        // loading: false,
        trendingByPurchaseTrackLoading: false,
        getInitialTrackError: false,
      };
    }

    //Albums - management
    //GET_NEW_INITIAL_ALBUMS
    case "GET_NEW_INITIAL_ALBUMS": {
      return {
        ...state,
        getNewInitialAlbums: action?.payload,
        // loading: false,
        newInitialAlbumsLoading: false,
        getInitialAlbumsError: false,
      };
    }
    //GET_POPULAR_INITIAL_ALBUMS
    case "GET_POPULAR_INITIAL_ALBUMS": {
      return {
        ...state,
        getPopularInitialAlbums: action?.payload,
        popularInitialAlbumsLoading: false,
        // loading: false,
        // getInitialAlbumsLoading: false,
        getInitialAlbumsError: false,
      };
    }
    //GET_MUST_HAVE_INITIAL_ALBUMS
    case "GET_MUST_HAVE_INITIAL_ALBUMS": {
      return {
        ...state,
        getMustHaveInitialAlbums: action?.payload,
        mustHaveInitialAlbumsLoading: false,
        // loading: false,
      };
    }
    //GET_TRENDING_BY_PLAYS_INITIAL_ALBUMS
    case "GET_TRENDING_BY_PLAYS_INITIAL_ALBUMS": {
      return {
        ...state,
        getTrendingByPlaysInitialAlbums: action?.payload,
        trendingByPlaysInitialAlbumsLoading: false,
        // loading: false,
        // getInitialAlbumsLoading: false,
        getInitialAlbumsError: false,
      };
    }
    //GET_TRENDING_BY_PURCHASE_INITIAL_ALBUMS
    case "GET_TRENDING_BY_PURCHASE_INITIAL_ALBUMS": {
      return {
        ...state,
        getTrendingByPurchaseInitialAlbums: action?.payload,
        trendingByPurchaseInitialAlbumsLoading: false,
        // loading: false,
        // getInitialAlbumsLoading: false,
        getInitialAlbumsError: false,
      };
    }
    //GET_SHARE_INDIVIDUAL_TRACK
    case "GET_SHARE_INDIVIDUAL_TRACK": {
      return {
        ...state,
        getShareIndividualTrack: action?.payload,
      };
    }

    case Actions.GET_DYNAMIC_STORE_LOADER: {
      return {
        ...state,
        getStoreSectionLoader: action?.payload,
      };
    }
    case Actions.GET_STORE_SECTION: {
      return {
        ...state,
        getStoreSection: action?.payload,
      };
    }

    case 'GET_DYNAMIC_STORE_SECTION_ITEM_DETAIL_LOADER': {
      return {
        ...state,
        getStoreSectionItemDetailLoader: action?.payload,
      };
    }
    case Actions.GET_STORE_SECTION_ITEM_DETAIL: {
      const stateVar = state.getStoreSectionItemDetail;
      const ActionVar = action?.payload;
      const final = mergeObjectsWithUniqueIds(...stateVar, ActionVar);
      return {
        ...state,
        getStoreSectionItemDetail: final,
        getStoreSectionItemDetailLoader: false,
      };
    }

    case Actions.TOTAL_ATTEMPTS_CHECK: {
      return {
        ...state,
        failedPayment: action?.payload,
      };
    }
    default: {
      return state;
    }
  }
};
export default albums;
