import axios from "axios";
import { axiosInstance } from "../axios-config";
import { showToast } from "../../common/function/function";
import { errorLog } from "../../common/utils";
/* Simple Login User */

export const postLogin = (value) =>
  new Promise((resolve, reject) =>
    axiosInstance
      .post(`auth/login`, value)
      .then((response) => {
        resolve(response && response.data && response.data.data);
        if (!response?.data?.data?.already_logged_in && response?.data?.message)
          showToast("Successfully !", response?.data?.message, "success");
      })
      .catch((error) => {
        errorLog(error);
        error.response && error.response.data.message
          ? showToast("Failed !", error.response.data.message, "error")
          : reject({ message: `Something Went Wrong` });
        resolve("error");
      })
  );

/* Simple Login User */
export const replacePostLogin = (value) =>
  new Promise((resolve, reject) =>
    axiosInstance
      .post(`auth/replace-login`, value)
      .then((response) => {
        resolve(response && response.data && response.data.data);
        showToast("Successfully !", response?.data?.message, "success");
      })
      .catch((error) => {
        errorLog(error);
        error.response && error.response.data.message
          ? showToast("Failed !", error.response.data.message, "error")
          : reject({ message: `Something Went Wrong` });
      })
  );
/* Get Verify user  */

export const getAuthVerify = (value) =>
  new Promise((resolve, reject) =>
    axiosInstance
      .get(`auth/verify/${value}`)
      .then((response) => {
        resolve(response && response.data && response.data.data);
        if (response && response.data && response.data.data) {
          showToast("Successfully !", response?.data?.message, "success");
        }
      })
      .catch((error) => {
        errorLog(error);
        error.response && error.response.data.message
          ? showToast("Failed !", error.response.data.message, "error")
          : reject({ message: `Something Went Wrong` });
        resolve(error);
      })
  );

/* Register user  */
export const postSignUp = (value) =>
  new Promise((resolve, reject) =>
    axiosInstance
      .post(`auth/register`, value)
      .then((response) => {
        resolve(response.data?.message);
        showToast("Successfully !", response?.data?.message, "success");
      })
      .catch((error) => {
        errorLog(error);
        error.response && error.response.data.message
          ? showToast("Failed !", error.response.data.message, "error")
          : reject({ message: `Something Went Wrong` });
      })
  );

/* Forgot password */
export const getForgotPassword = (value) =>
  new Promise((resolve, reject) =>
    axiosInstance
      .get(`auth/forgot-password/${value}`)
      .then((response) => {
        resolve(response && response?.data && response?.data?.message);
        showToast("Successfully !", response?.data?.message, "success");
      })
      .catch((error) => {
        errorLog(error);
        error.response && error.response.data.message
          ? showToast("Failed !", error.response.data.message, "error")
          : reject({ message: `Something Went Wrong` });
        resolve(error.response);
      })
  );

/* Logout User */
export const getLogout = () =>
  new Promise((resolve, reject) =>
    axiosInstance
      .get(`auth/logout`)
      .then((response) => {
        resolve(response && response?.data);
        showToast("Successfully !", response?.data?.message, "success");
      })
      .catch((error) => {
        errorLog(error);
        error.response && error.response.data.message
          ? showToast("Failed !", error.response.data.message, "error")
          : reject({ message: `Something Went Wrong` });
      })
  );

/*Account setting */
export const accountSetting = (values) =>
  new Promise((resolve, reject) =>
    axiosInstance
      .post(`users/update-profile`, {
        fullname: values?.fullname,
        password: values?.password,
      })
      .then((response) => {
        resolve(response?.data?.data);
        showToast("Successfully !", response?.data?.message, "success");
      })
      .catch((error) => {
        errorLog(error);
        error.response && error.response.data.message
          ? showToast("Failed !", error.response.data.message, "error")
          : reject({ message: `Something Went Wrong` });
      })
  );

/*Account setting */
export const changePassword = (values) =>
  new Promise((resolve, reject) =>
    axiosInstance
      .post(`users/change-password`, {
        password: values?.password,
        new_password: values?.new_password,
        confirm_password: values?.confirm_password,
      })
      .then((response) => {
        resolve(response?.data?.message);
        showToast("Successfully !", response?.data?.message, "success");
      })
      .catch((error) => {
        errorLog(error);
        error.response && error.response.data.message
          ? showToast("Failed !", error.response.data.message, "error")
          : reject({ message: `Something Went Wrong` });
      })
  );

export const contactUs = (values) =>
  new Promise((resolve, reject) =>
    axiosInstance
      .post(`contact-us`, {
        email: values.email,
        message: values.message,
        name: values.name,
        subject: values.subject,
      })
      .then((response) => {
        resolve(response?.data?.data);
        showToast("Successfully !", response?.data?.message, "success");
      })
      .catch((error) => {
        errorLog(error);
        error.response && error.response.data.message
          ? showToast("Failed !", error.response.data.message, "error")
          : reject({ message: `Something Went Wrong` });
      })
  );

/* Resend mail for verification */
export const getSendMail = (value) =>
  new Promise((resolve, reject) =>
    axiosInstance
      .get(`auth/resend-verification/${value}`)
      .then((response) => {
        resolve(response?.data?.message);
        // showToast("Successfully !", response?.data?.message, "success");
      })
      .catch((error) => {
        errorLog(error);
        error.response && error.response.data.message
          ? showToast("Failed !", error.response.data.message, "error")
          : reject({ message: `Something Went Wrong` });
      })
  );

/* Reset Password */
export const postResetPassword = (value) =>
  new Promise((resolve, reject) =>
    axiosInstance
      .post(`auth/reset-password`, value)
      .then((response) => {
        resolve(response && response?.data && response?.data?.message);
        showToast("Successfully !", response?.data?.message, "success");
      })
      .catch((error) => {
        errorLog(error);
        error.response && error.response.data.message
          ? showToast("Failed !", error.response.data.message, "error")
          : reject({ message: `Something Went Wrong` });
      })
  );

export const getConnectWithBB = () =>
  new Promise((resolve, reject) =>
    axiosInstance
      .get(`auth/connect-with-bb`)
      .then((response) => {
        resolve(response);
        showToast("Successfully !", response?.data?.message, "success");
      })
      .catch((error) => {
        errorLog(error);
        error.response && error.response.data.message
          ? showToast("Failed !", error.response.data.message, "error")
          : reject({ message: `Something Went Wrong` });
      })
  );

/* Multiple User Invite*/
export const multipleInviteUser = (value) =>
  new Promise((resolve, reject) =>
    axiosInstance
      .post(`auth/invite-multiple-for-trial`, value)
      .then((response) => {
        resolve(response.data.message);
        showToast("Successfully !", response?.data?.message, "success");
      })
      .catch((error) => {
        error.response && error.response.data.message
          ? showToast("Failed !", error.response.data.message, "error")
          : reject({ message: `Something Went Wrong` });
      })
  );

export const getDashBoardCardData = (params) =>
  new Promise((resolve, reject) =>
    axiosInstance
      .get("auth/dashboard", { params })
      .then((response) => {
        resolve(response.data?.data);
      })
      .catch((error) => {
        error.response && error.response.data.message
          ? showToast("Failed !", error.response.data.message, "error")
          : reject({ message: `Something Went Wrong` });
      })
  );
export const getDashBoardTableData = (params) =>
  new Promise((resolve, reject) =>
    axiosInstance
      .get("/auth/dashboard/track-purchase", { params })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        error.response && error.response.data.message
          ? showToast("Failed !", error.response.data.message, "error")
          : reject({ message: `Something Went Wrong` });
      })
  );
export const getDashBoardContactData = (params) =>
  new Promise((resolve, reject) =>
    axiosInstance
      .get("/contact-us", { params })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        error.response && error.response.data.message
          ? showToast("Failed !", error.response.data.message, "error")
          : reject({ message: `Something Went Wrong` });
      })
  );

export const onResolveTicket = (id, params) =>
  new Promise((resolve, reject) =>
    axiosInstance
      .patch(`/contact-us/${id}`, params)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        error.response && error.response.data.message
          ? showToast("Failed !", error.response.data.message, "error")
          : reject({ message: `Something Went Wrong` });
      })
  );

export const getDashBoardSubscriptionTableData = (params) =>
  new Promise((resolve, reject) =>
    axiosInstance
      .get("/auth/dashboard/user-subscription-detail", { params })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        error.response && error.response.data.message
          ? showToast("Failed !", error.response.data.message, "error")
          : reject({ message: `Something Went Wrong` });
      })
  );

export const refreshAccessToken = (params) =>
  new Promise((resolve, reject) =>
    axios
      .post(`${process.env.NEXT_PUBLIC_BACKEND_API_URL}auth/refresh`, {
        refresh_token: params,
      })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      })
  );

// partner
export const getPartnerDashBoardData = (params) =>
  new Promise((resolve, reject) =>
    axiosInstance
      .get("/partner/dashboard/summary", { params })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        error.response && error.response.data.message
          ? showToast("Failed !", error.response.data.message, "error")
          : reject({ message: `Something Went Wrong` });
      })
  );

export const getPartnerPerformanceCatalogData = (params) =>
  new Promise((resolve, reject) =>
    axiosInstance
      .get("/partner/dashboard/catalog", { params })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        error.response && error.response.data.message
          ? showToast("Failed !", error.response.data.message, "error")
          : reject({ message: `Something Went Wrong` });
      })
  );
export const getPartnerActivitySalesLogs = (params) =>
  new Promise((resolve, reject) =>
    axiosInstance
      .get("/partner/dashboard/activity-sales-log", { params })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        error.response && error.response.data.message
          ? showToast("Failed !", error.response.data.message, "error")
          : reject({ message: `Something Went Wrong` });
      })
  );

export const getPartnerActivitySubscriptionLogs = (params) =>
  new Promise((resolve, reject) =>
    axiosInstance
      .get("/partner/dashboard/activity-subscription-log?", { params })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        error.response && error.response.data.message
          ? showToast("Failed !", error.response.data.message, "error")
          : reject({ message: `Something Went Wrong` });
      })
  );

export const getPerformanceAnalyticsLogs = (params) =>
  new Promise((resolve, reject) =>
    axiosInstance
      .get("/partner/dashboard/performance/analytics", { params })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        error.response && error.response.data.message
          ? showToast("Failed !", error.response.data.message, "error")
          : reject({ message: `Something Went Wrong` });
      })
  );

// artist
export const getArtistDashBoardData = (params) =>
  new Promise((resolve, reject) =>
    axiosInstance
      .get("/artists/dashboard", { params })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        error.response && error.response.data.message
          ? showToast("Failed !", error.response.data.message, "error")
          : reject({ message: `Something Went Wrong` });
      })
  );

export const getArtistPerformanceCatalogData = (params) =>
  new Promise((resolve, reject) =>
    axiosInstance
      .get("/artists/dashboard/catalog", { params })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        error.response && error.response.data.message
          ? showToast("Failed !", error.response.data.message, "error")
          : reject({ message: `Something Went Wrong` });
      })
  );
export const getArtistActivitySalesLogs = (params) =>
  new Promise((resolve, reject) =>
    axiosInstance
      .get("/artists/dashboard/activity-sales-log", { params })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        error.response && error.response.data.message
          ? showToast("Failed !", error.response.data.message, "error")
          : reject({ message: `Something Went Wrong` });
      })
  );

export const getPartnerEarningLifeTimeCurrent = (params) =>
  new Promise((resolve, reject) =>
    axiosInstance
      .get("/partner/dashboard/earning/lifetime-current", { params })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        error.response && error.response.data.message
          ? showToast("Failed !", error.response.data.message, "error")
          : reject({ message: `Something Went Wrong` });
      })
  );

export const getPartnerWithdrawal = (id) =>
  new Promise((resolve, reject) =>
    axiosInstance
      .get(`/partner-withdrawal/${id}`)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        error.response && error.response.data.message
          ? showToast("Failed !", error.response.data.message, "error")
          : reject({ message: `Something Went Wrong` });
      })
  );

export const getPaypalIdByPartner = (id, params) =>
  new Promise((resolve, reject) =>
    axiosInstance
      .patch(`/partner/paypal-email/${id}`, params)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        error.response && error.response.data.message
          ? showToast("Failed !", error.response.data.message, "error")
          : reject({ message: `Something Went Wrong` });
      })
  );

export const getPartnerById = (id) =>
  new Promise((resolve, reject) =>
    axiosInstance
      .get(`/partner/${id}`)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        error.response && error.response.data.message
          ? showToast("Failed !", error.response.data.message, "error")
          : reject({ message: `Something Went Wrong` });
      })
  );
