import * as Actions from "../actions/auth/index";
import {
  setLocal,
  removeLocal,
  setCookie,
} from "../../common/function/storage";
const initialState = {
  authStore: {},
  librarySearchValue: "",
  selectedPlanId: "",
  draftPlanPayload: {},
  checkoutButtonClick: true,
  albumsTypes: null,
  count: 0,
  isLockSubscribeBtn: false
};

export default function memorize(state = initialState, action) {
  switch (action.type) {
    case Actions.POST_ACCOUNT_SETTING: {
      const userDetails = {
        fullname: action?.payload?.fullname,
        email: action?.payload?.email,
      };
      removeLocal("login_user");
      setCookie("login_user", JSON.stringify(userDetails));
      // setLocal("login_user", JSON.stringify(userDetails));
      return { ...state, authStore: action?.payload };
    }

    case Actions.POST_LOGIN: {
      const userDetails = {
        fullname: action?.payload?.fullname,
        email: action?.payload?.email,
        already_logged_in: action?.payload?.already_logged_in,
        bb_user_id: action?.payload?.bb_user_id,
        connected_to_bb: action?.payload?.connected_to_bb,
        first_time_login: false,
        public_id: action?.payload?.public_id,
        reset_password: action?.payload?.reset_password,
        partner_id:action?.payload?.partner_id,
        artist_id:action?.payload?.artist_id,
        rolename: action?.payload?.rolename,
      };
      setCookie("login_user", JSON.stringify(userDetails));
      return { ...state, authStore: action?.payload };
    }

    case Actions.GET_AUTH_VERITY: {
      const userDetails = {
        fullname: action?.payload?.fullname,
        email: action?.payload?.email,
        already_logged_in: action?.payload?.already_logged_in,
        bb_user_id: action?.payload?.bb_user_id,
        connected_to_bb: action?.payload?.connected_to_bb,
        first_time_login: true,
        public_id: action?.payload?.public_id,
        reset_password: action?.payload?.reset_password,
        partner_id:action?.payload?.partner_id,
        artist_id:action?.payload?.artist_id,
        rolename: action?.payload?.rolename,
      };
      setCookie("login_user", JSON.stringify(userDetails));
      setCookie("access_token", action?.payload?.access_token);
      setCookie("refresh_token", action?.payload?.refresh_token);
      return { ...state, authStore: action?.payload };
    }

    case "ALBUMS_TYPE": {
      return {
        ...state,
        albumsTypes: action?.payload,
      };
    }

    case "CLEAR_AUTH_STORE": {
      return {
        ...state,
        authStore: action?.payload,
      };
    }
    case "LIBRARY_SEARCH_VALUE": {
      return {
        ...state,
        librarySearchValue: action?.payload,
      };
    }
    case "SELECTED_PLAN_ID": {
      return {
        ...state,
        selectedPlanId: action?.payload,
      };
    }
    case "SELECTED_PAYMENT_PLAN": {
      return {
        ...state,
        selectedPaymentPlan: action?.payload,
      };
    }
    case "DRAFT_API_PAYLOAD": {
      return {
        ...state,
        draftPlanPayload: action?.payload,
      };
    }
    case "CHECKOUT_BUTTON_CLICK": {
      return {
        ...state,
        checkoutButtonClick: action?.payload,
      };
    }
    case "HOME_PAGE_COUNT": {
      return {
        ...state,
        count: action?.payload,
      };
    }
    case "IS_LOCK_SUBSCRIBE": {
      return {
        ...state,
        isLockSubscribeBtn: action?.payload,
      };
    }
    default: {
      return state;
    }
  }
}
