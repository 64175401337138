import log from "loglevel";

import * as Sentry from "@sentry/react";

import { CaptureConsole } from "@sentry/integrations";
import { BrowserTracing } from "@sentry/tracing";
/**
 * It initializes the Sentry SDK.
 */
export const initLog = (version) => {
  Sentry.init({
    ignoreErrors: [
      /Network Error/i,
      /Request aborted/i,
      /Failed to fetch/i,
      /Loading initial props cancelled/i,
      /expected expression, got keyword 'var'/i,
      /Route did not complete loading.*/i,
      /Abort fetching component for route:.*/i,
      /Failed to load static props/i,

      /NetworkError when attempting to fetch resource.*/i,
      /The fetching process for the media resource was aborted by the user agent at the user's request.*/i,
      /The request is not allowed by the user agent or the platform in the current context, possibly because the user denied permission.*/i,
    ],
    // dsn: process.env.REACT_APP_SENTRY_KEY,
    environment: process.env.NEXT_PUBLIC_ENVIRONMENT,
    dsn: process.env.NEXT_PUBLIC_SENTRY_DNS
      ? process.env.NEXT_PUBLIC_SENTRY_DNS
      : "https://86369996d9bd4fd48e9aaf10c1bc824d@o154616.ingest.sentry.io/4504365331120128",
    /* A way to capture the console.logs and send them to Sentry. */
    integrations: [
      new BrowserTracing(),
      new CaptureConsole({
        defaults: ["error"],
        levels: ["error"],
      }),
    ],
    release: `bb2-website@${process.env.NEXT_PUBLIC_ENVIRONMENT} ${version}`,
    //tracesSampleRate: 1.0,
  });
};

export const infoLog = () => {
  log.init("init value");
};
