import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Router, { useRouter } from "next/router";
import style from "./button-navigation.module.scss";
import { getLocal, removeLocal, setLocal } from "../../common/function/storage";
import useWindowDimensions from "../../common/function/hooks/use-window-dimensions";
import { checkLogOrNot } from "../../common/function/function";
const index = () => {
  const location = useRouter();
  const dispatch = useDispatch();
  const _TOKEN = getLocal("access_token");
  const { width } = useWindowDimensions();
  let isMobile = width <= 767;
  const _OPEN_MORE_MENU = useSelector((state) => state.common.openMoreMenu);
  const _CART_DROP_DOWN_TOGGLE = useSelector(
    (e) => e.common.cartDropDownToggle
  );
  const _OPEN_MOBILE_SEARCH = useSelector((e) => e?.common?.openMobileSearch);
  const CloseMoreMenu = () => {
    dispatch({ type: "OPEN_MORE_MENU", payload: false });
    dispatch({
      type: "SEARCH_VALUE",
      payload: "",
    });
    dispatch({
      type: "ON_CLICK_SEARCH_HISTORY",
      payload: "",
    });
    dispatch({
      type: "[ALBUMS] GET_OPEN_FILTER_RESPONSE",
      payload: null,
    });
    dispatch({
      type: "OPEN_MOBILE_SEARCH",
      payload: false,
    });
  };
  const clearSearch = () => {
    dispatch({
      type: "SEARCH_VALUE",
      payload: "",
    });
    dispatch({
      type: "ON_CLICK_SEARCH_HISTORY",
      payload: "",
    });
    dispatch({
      type: "[ALBUMS] GET_OPEN_FILTER_RESPONSE",
      payload: null,
    });
  };
  const commonClick = useCallback((routerName, setLocalName) => {
    Router.push({
      pathname: `${routerName}`,
    });
    removeLocal("routers");
    setLocal("routers", `${setLocalName}`);
    CloseMoreMenu();
  });
  useEffect(() => {
    removeLocal("/");
    setLocal("routers", "/");
  });
  useEffect(() => {
    const body = document.querySelector("body");
    body.style.overflow = _OPEN_MORE_MENU ? "hidden" : "auto";
  }, [_OPEN_MORE_MENU]);

  const commonSearchClick = () => {
    if (
      location &&
      (location?.pathname === "/contact" ||
        location?.pathname === "/" ||
        location?.pathname === "/about-us" ||
        location?.pathname === "/auth/signin" ||
        location?.pathname === "/auth/forgotpassword" ||
        location?.pathname === "/auth/signUp" ||
        location?.pathname === "/change-plan")
    ) {
      commonClick("/store/explore", "/store/explore");
    }
    dispatch({
      type: "OPEN_MOBILE_SEARCH",
      payload: !_OPEN_MOBILE_SEARCH,
    });
    dispatch({ type: "OPEN_MORE_MENU", payload: false });
    window.scrollTo(0, 0);
    clearSearch();
  };
  return (
    <React.Fragment>
      <div
        className={`${style.button_navigation_main_div} flex`}
        style={{
          zIndex:
            isMobile && _CART_DROP_DOWN_TOGGLE
              ? "99"
              : _OPEN_MORE_MENU
              ? "999999"
              : "9999",
        }}
      >
        <div
          className={`${style.navigation_icon_div}`}
          onClick={() => {
            checkLogOrNot(_TOKEN);
            removeLocal("active"), setLocal("active", `/`);
            removeLocal("routers");
            dispatch({
              type: "OPEN_MOBILE_SEARCH",
              payload: false,
            });
            CloseMoreMenu();
          }}
        >
          <i
            className={`icon-home  ${
              !_OPEN_MORE_MENU &&
              !_OPEN_MOBILE_SEARCH &&
              location &&
              location?.asPath === "/"
                ? "navigation_icon_active"
                : ""
            }
          `}
          ></i>
          <label
            className={`${
              !_OPEN_MORE_MENU &&
              !_OPEN_MOBILE_SEARCH &&
              location &&
              location?.asPath === "/"
                ? "navigation_icon_active"
                : ""
            }
          `}
          >
            Home
          </label>
        </div>
        <div
          className={`${style.navigation_icon_div}`}
          onClick={() => {
            commonClick("/store/explore", "/store/explore");
          }}
        >
          <i
            className={`icon-store-solid-icon ${
              !_OPEN_MORE_MENU &&
              !_OPEN_MOBILE_SEARCH &&
              location &&
              location?.pathname === "/store/explore"
                ? "navigation_icon_active"
                : ""
            }`}
          ></i>
          <label
            className={`${
              !_OPEN_MORE_MENU &&
              !_OPEN_MOBILE_SEARCH &&
              location &&
              location?.pathname === "/store/explore"
                ? "navigation_icon_active"
                : ""
            }`}
          >
            Store
          </label>
        </div>
        <div
          className={`${style.navigation_icon_div}`}
          onClick={() => {
            commonClick("/store/albums", "/store/albums");
          }}
        >
          <i
            className={`icon-Albums-icon ${
              !_OPEN_MORE_MENU &&
              !_OPEN_MOBILE_SEARCH &&
              location &&
              location?.pathname === "/store/albums"
                ? "navigation_icon_active"
                : ""
            }`}
          ></i>
          <label
            className={`${
              !_OPEN_MORE_MENU &&
              !_OPEN_MOBILE_SEARCH &&
              location &&
              location?.pathname === "/store/albums"
                ? "navigation_icon_active"
                : ""
            }`}
          >
            Albums
          </label>
        </div>

        <div
          className={`${style.navigation_icon_div}`}
          onClick={() => {
            commonSearchClick();
          }}
        >
          <i
            className={`icon-search ${
              _OPEN_MOBILE_SEARCH ? "navigation_icon_active" : ""
            }`}
          ></i>
          <label
            className={`${_OPEN_MOBILE_SEARCH ? "navigation_icon_active" : ""}`}
          >
            Search
          </label>
        </div>
        <div
          className={`${style.navigation_icon_div}`}
          onClick={() => {
            dispatch({ type: "OPEN_MORE_MENU", payload: !_OPEN_MORE_MENU });
            dispatch({
              type: "OPEN_MOBILE_SEARCH",
              payload: false,
            });
          }}
        >
          <label className={`${_OPEN_MORE_MENU ? style.more_menu_active : ""}`}>
            <i className="icon-more-3-svgrepo-com"></i>
          </label>
          <label
            className={`${_OPEN_MORE_MENU ? "navigation_icon_active" : ""}`}
          >
            More
          </label>
        </div>
        <div className="bottom-white-line-div"></div>
      </div>
    </React.Fragment>
  );
};

export default index;
