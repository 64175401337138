import { showToast } from "../../common/function/function";
import { axiosInstance } from "../axios-config";

export const getAllPartners = () =>
  new Promise((resolve, reject) =>
    axiosInstance
      .get("/partner")
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        error.response && error.response.data.message
          ? showToast("Failed !", error.response.data.message, "error")
          : reject({ message: `Something Went Wrong` });
      })
  );


  